"use strict";

$.widget("custom.enclosure", {
    _create: function () {
        this.editors = this.element.find('.text-editor').each(function () { $(this).textEditor() });
        this.file = this.element.find('input[name*=file]');
        this._on(this.element, { submit: '_submitFormEvent' });
        this._on(this.file, { change: '_selectFileEvent' });
        return this;
    },
    /**
     * Handle form submission event
     */
    _submitFormEvent: function (e) {
        this.editors.each(function () { $(this).textEditor('serialize') });
        return true;
    },
    /**
     * Handle file selection event
     */
    _selectFileEvent: function (e) {
        let file = (0 < e.target.files.length ? e.target.files[0] : null);
        this.element.find('span.filename').html('~');
        if (!file) {
            return false;
        }
        let maxsize = $(e.target).data('maxsize').toLowerCase();
        let unit = maxsize.replace(/[^bkmgtpezy]/ig, '');
        let size = maxsize.replace(/[^0-9]/ig, '');
        let maxbytes = (unit ? (size * Math.pow(1000, 'bkmgtpezy'.search(unit))) : $size);
        if (file.size > maxbytes || 0 > $(e.target).data('filetype').split(',').indexOf(file.type)) {
            $(e.target).wrap('<form>').closest('form').get(0).reset();
            $(e.target).unwrap();
            showDialog('#modal-dialog-invalid-file').show();
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        this.element.find('span.filename').html(file.name);
        return false;
    }
});
