//
// SKIP LINK
//
var fn_nav_skip = function () {

  $( '.nav-skip a, .nav-skip button' ).focus(function () {
    $(this).parent().toggleClass('--visible');
  }).blur( function () {
    $(this).parent().toggleClass('--visible');
  });

};