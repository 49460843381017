"use strict";

var step6EnclosureManager = function () {
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        $('form[name=enclosure]').enclosure(/*{ registry: tableOfContentList }*/);
    };
    return init();
};
