'use strict';

$.widget('custom.account', {
    /**
     * Constructor
     */
    _create: function () {
        this.switch = this.element.find('div.switch-button');
        this.notify = this.element.find('input[name$="[notify]"]');
        this._on(this.switch, { click: '_eventSwitchNotify' });
    },
    /**
     * [event binding] on plan thumbnail click
     */
    _eventSwitchNotify: function (e) {
        e.preventDefault();
        this.notify.val($(e.target).hasClass('active') ? 1 : 0);
        return false;
    },
});
