"use strict";

var administrationPages = function () {
    /**
     * Init manage binding and initialization
     */
    var resetForm = function (form) {
        form.find('input:text, input:password, select').val('');
        form.find('input:text, input:password, input:file, select').removeClass('is-invalid');
        form.find('span.invalid-feedback').remove();
    };

    var init = function () {
        // form page document
        var formDocument = $('section.administration form[name=page_document_edit_form]');

        // open dialog document edit form
        var openDialogDocumentEditForm = function () {
            var dialogEditDocument = showDialog('#modal-dialog-edit-document');

            dialogEditDocument.on('hide', function () {
                resetForm(formDocument);
            });

            return dialogEditDocument;
        };

        // add document
        var editDocumentBtn = $('.administration--gestion-utilisateurs .edit-document > button');
        editDocumentBtn.off('click').on('click', function () {
            var dialogEditDocument = openDialogDocumentEditForm();
        });

        if (true === editDocumentBtn.data('trigger')) {
            var dialogEditDocument = openDialogDocumentEditForm();
        }

        // form list
        var formList = $('section.administration form[name=list-documents]');

        // classement selects
        formList.find('select.change-classement[data-link]').off('change').on('change', function () {
            var self = $(this);
            resetForm(formDocument);

            $.ajax({
                url: self.data('link').replace(/\/0$/i, '/' + self.val()),
                method: 'POST'
            }).done(function () {
                self.blur();
            });
        });

        // order selects
        formList.find('select.change-ordre[data-link]').off('change').on('change', function () {
            var self = $(this);
            resetForm(formDocument);

            $.ajax({
                url: self.data('link').replace(/\/0$/i, '/' + self.val()),
                method: 'POST'
            }).done(function () {
                self.blur();
                document.location = self.data('redirect');
            });
        });

        // document delete
        formList.find('button.delete[data-link]').off('click').on('click', function () {
            var self = $(this);
            var element = '#modal-dialog-confirm-delete-document';
            var dialog = showDialog(element);
            var modal = $(element);

            // bind confirm button click
            modal.off('click', 'button.yes').on('click', 'button.yes', function () {
                resetForm(formDocument);

                $.ajax({
                    url: self.data('link'),
                    method: 'POST'
                }).done(function () {
                    dialog.hide();
                    document.location = self.data('redirect');
                });
                return false;
            });
            modal.off('click', 'button.no').on('click', 'button.no', function () {
                dialog.hide();
                return false;
            });
        });

    };
    return init();
};
