var dragon_drop_init = function () {
    const targetDragonDrop = document.getElementById('step6_form_enclosures');
    const dragonDrop = new DragonDrop(targetDragonDrop, {
        handle: '.button-a11y-handle',
        announcement: {
            grabbed: function (el) { el.querySelector('span').innerText + ' grabbed' },
            dropped: function (el) { el.querySelector('span').innerText + ' dropped' },
            reorder: function (el, items) {
                const pos = items.indexOf(el) + 1;
                const text = el.querySelector('span').innerText;
                return 'The rankings have been updated, ' + text + ' is now ranked #' + pos + ' out of ' + items.length;
            },
            cancel: 'Reranking cancelled.'
        }
    });
}
