var tableOfContentNestedSortable = function () {
    //move la branche avec les fleches du clavier
    function BranchToMove(handle, dom, tableType) {
        this.handle = handle;
        this.dom = dom;
        this.isSelected = false;
        this.isFirstElement = false;
        this.isLastElement = false;
        this.maxNestingLevel = (tableType == "americain" ? 7 : 0);
        this.level = 0;

        this.setFirstElement = function () {
            var nbPreviousElements = this.dom.prevAll().length;
            this.isFirstElement = (nbPreviousElements == 0) ? true : false;
        };
        this.setLastElement = function () {
            var nbNextElements = this.dom.nextAll().length;
            this.isLastElement = (nbNextElements == 0) ? true : false;
        }
        this.findLevel = function () {
            var ancestors = this.dom.parentsUntil(".table-of-contents", "ol");
            this.level = ancestors.length + 1;
        }
        this.getToggleContentId = function () {
            var toggles = this.dom.closest(".table-of-contents").find("ol[id^='toggle-content-target']");
            var toggleIdList = []

            if (toggles.length != 0) {
                toggles.each(function () {
                    toggleIdSplit = $(this).attr("id").split("-");
                    toggleIdSplitNumber = toggleIdSplit[toggleIdSplit.length - 1];
                    toggleIdList.push(parseInt(toggleIdSplitNumber));
                });
                return Math.max.apply(null, toggleIdList) + 1;
                // return Math.max(...toggleIdList) + 1;
            }
            else {
                return (1)
            }
        }
        this.moveUp = function () {
            if (this.isSelected && !this.isFirstElement) {
                this.dom.insertBefore(this.dom.prev("li"));
                putFocusOnNewElement(this.dom);
            }
        };
        this.moveDown = function () {
            if (this.isSelected && !this.isLastElement) {
                this.dom.insertAfter(this.dom.next("li"));
                putFocusOnNewElement(this.dom);
            }
        }
        this.moveRightToggle = function () {
            if (this.isSelected && !this.isFirstElement && this.maxNestingLevel != 0 && this.level < this.maxNestingLevel) {
                var previousElement = this.dom.prev();
                if (previousElement.has("ol").length == 0) {
                    toggleNumber = this.getToggleContentId();
                    previousElement.append('<ol id="toggle-content-target-' + toggleNumber
                        + '" aria-labelledby="toggle-content-trigger-' + toggleNumber
                        + '"></ol>');
                    previousElement.children("div").append('<button id="toggle-content-trigger-' + toggleNumber + '" '
                        + 'type="button" class="toggle-content" '
                        + 'aria-controls="toggle-content-target-' + toggleNumber + '" '
                        + 'aria-expanded="false">'
                        + '<span class="icon-arrow-down"></span>'
                        + '</button>');
                    previousElement.children("ol").append(this.dom);
                    this.handle.focus();
                } else {
                    this.dom.insertAfter(previousElement.children("ol").children("li").last());
                    this.handle.focus();
                }
            }
        }
        this.moveRight = function () {

            if (this.isSelected && !this.isFirstElement && this.maxNestingLevel != 0 && this.level < this.maxNestingLevel) {
                var previousElement = this.dom.prev();

                if ($("ol", previousElement).length == 0) {
                    previousElement.append('<ol></ol>');
                    previousElement.children("ol").append(this.dom);
                } else {
                    this.dom.insertAfter(previousElement.children("ol").children("li").last());
                }

                putFocusOnNewElement(this.dom);
            }
        }
        this.moveLeftToggle = function () {
            if (this.isSelected && !this.isFirstElement && this.maxNestingLevel != 0) {
                var nbSiblings = this.dom.siblings().length;
                this.dom.insertAfter(this.dom.parent("ol").parent("li").last());
                if (nbSiblings == 0) {
                    this.dom.prev("li").children("div").children("button[id^='toggle-content-trigger']").remove();
                    this.dom.prev("li").children("ol").remove();
                }
                this.handle.focus();
            }

        }
        this.moveLeft = function () {

            if (this.isSelected && this.level > 1 && this.maxNestingLevel != 0) {
                var nbSiblings = this.dom.siblings().length;
                this.dom.insertAfter(this.dom.parent("ol").parent("li").last());

                if (nbSiblings == 0) {
                    //this.dom.prev("li").children("div").children("button[id^='toggle-content-trigger']").remove();
                    this.dom.prev("li").children("ol").remove();
                }

                putFocusOnNewElement(this.dom);
            }

        }
        this.setSelected = function (value) {
            if (typeof value == "boolean") {
                this.isSelected = value;

                if (value) {
                    this.handle.addClass("handle--selected");
                    this.handle.attr("aria-pressed", "true");
                } else {
                    this.handle.removeClass("handle--selected");
                    this.handle.attr("aria-pressed", "false");
                }
            }
        }
    }

    //change using keyboard
    $(".table-of-contents").on("focus", ".mjs-nestedSortable-handle", function (event) {
        var tableOfContentType = $(this).closest(".table-of-contents").attr("data-tableofcontent-type");
        var currentBranch = new BranchToMove($(this), $(this).closest("li"), tableOfContentType);

        currentBranch.setFirstElement();
        currentBranch.setLastElement();
        currentBranch.findLevel();

        $(this).bind('keydown', function (eventkey) {
            switch (eventkey.which) {
                case 13:
                case 32:
                    eventkey.preventDefault();
                    currentBranch.setSelected(!this.isSelected);
                    break;
                case 37:
                    currentBranch.moveLeft();
                    break;
                case 38:
                    currentBranch.moveUp();
                    break;
                case 39:
                    currentBranch.findLevel();
                    currentBranch.moveRight();
                    break;
                case 40:
                    currentBranch.moveDown();
                    break;
                case 9:
                    currentBranch.setSelected(false);
                    break;
            }
        });

    });

    function putFocusOnNewElement(dom) {
        var el = $($(".mjs-nestedSortable-handle", dom)[0]);
        var e = $.Event("keydown");
        e.which = 13;
        el.focus().trigger(e);
    }


    $(".table-of-contents").on("blur", ".mjs-nestedSortable-handle", function () {
        $(this).unbind('keydown');
        $(this).removeClass("handle--selected");
    });

    function getToggleContentId(table) {
        var toggles = table.find("ol[id^='a11y-toggle-content-target']");
        var toggleIdList = []
        if (toggles.length != 0) {
            toggles.each(function () {
                toggleIdSplit = $(this).attr("id").split("-");
                toggleIdSplitNumber = toggleIdSplit[toggleIdSplit.length - 1];
                toggleIdList.push(parseInt(toggleIdSplitNumber));
            });
            return Math.max.apply(null, toggleIdList) + 1;
            // return Math.max(...toggleIdList) + 1;
        }
        else {
            return (1)
        }
    }

    //update title
    $(".table-of-contents").on("click", "button.update-title-trigger", function () {
        var title = $(this).siblings(".item-title").first();
        var currrentTitleValue = title.text();
        title.replaceWith('<label for="new-title-1" class="access-hidden">modifier' + currrentTitleValue + '</label>'
            + '<input id="new-title-1" type="text" class="form-input form-control" value="'
            + currrentTitleValue + '">');
        $(this).addClass("update-title-validate").removeClass("update-title-trigger");
        $(this).children("span[class^='icon']").removeClass("icon-modifier").addClass("icon-validation")
    });

    // update title part 2
    $(".table-of-contents").on("click", "button.update-title-validate", function () {
        var titleInput = $(this).siblings("input");
        const titleValue = titleInput.val();
        titleInput.replaceWith('<span class="item-title" role="button" tabindex="0">' + titleValue + '</span>');
        $(this).addClass("update-title-trigger").removeClass("update-title-validate");
        $(this).children("span[class^='icon']").removeClass("icon-validation").addClass("icon-modifier")
    });


    // add item plan article sans titre
    $(document).on("click", "button.add-item--article-sans-titre", function (event) {
        var tableOfContent = $(this).siblings().next(".table-of-contents");
        var lastTitle = tableOfContent.children("li").last().children().children(".item-title").html();
        var newTitle = lastTitle == undefined ? "Article 1" : "Article " + (parseInt(lastTitle.split(" ")[lastTitle.split(" ").length - 1]) + 1);
        var newItem = '<li class="table-of-contents--item">'
            + '<div>'
            + '<span role="button" tabindex="0" class="mjs-nestedSortable-handle" aria-pressed="false">'
            + '<span class="icon-deplacer"></span>'
            + '<span class="access-hidden">Déplacer cet article</span>'
            + '</span>'
            + '<span class="item-title" role="button" tabindex="0">Art. </span>'
            + '<button type="button" class="delete-item">'
            + '<span class="icon-croix2"></span>'
            + '<span class="access-hidden">Supprimer cet article</span>'
            + '</button>'
            + '</div>'
            + '</li>'
        tableOfContent.append(newItem);
        tableOfContent.find('li.table-of-contents--item .item-title:last').focus();
        event.preventDefault();
    });

    function addItemArticleAvecTitre(submitButton) {
        var tableOfContent = submitButton.parent().siblings().next(".table-of-contents");
        var title = (submitButton.siblings("input.add-title").val() == undefined ? "" : submitButton.siblings("input.add-title").val());
        var type = submitButton.siblings(".form-group-select-container").children("select.add-type").val();
        var select = submitButton.siblings(".form-group-select-container").html();

        var numberTitle = submitButton.siblings(".tableContent-numberedTitle").children("input").val();
        var numberedTitle = submitButton.siblings(".tableContent-numberedTitle").html();

        var newItem = '<li class="table-of-contents--item">'
            + '<div>'
            + '<span role="button" tabindex="0" class="mjs-nestedSortable-handle" aria-pressed="false">'
            + '<span class="icon-deplacer"></span>'
            + '<span class="access-hidden">Déplacer cet article</span>'
            + '</span>'
            + '<div class="form-group-select-container">'
            + select
            + '</div>'
            + '<div class="tableContent-numberedTitle mr-20">'
            + numberedTitle
            + '</div>'
            + '<span class="item-title" role="button" tabindex="0">' + title + '</span>'
            + '<button id="update-content-trigger" type="button" class="update-title-trigger">'
            + '<span class="access-hidden">Modifier ' + title + '</span>'
            + '<span class="icon-modifier"></span>'
            + '</button>'
            + '<button type="button" class="delete-item">'
            + '<span class="icon-croix2"></span>'
            + '<span class="access-hidden">Supprimer' + title + '</span>'
            + '</button>'
            + '</div>'
            + '</li>'
        tableOfContent.append(newItem);
        tableOfContent.children("li").last().find(".add-type").val(type);
        tableOfContent.find('li.table-of-contents--item .item-title:last').focus();
        tableOfContent.find('li.table-of-contents--item .tableContent-numberedTitle input:last').val(numberTitle);

        //clean-up
        submitButton.siblings(".form-group-select-container").children("select.add-type").val("");
        submitButton.siblings(".tableContent-numberedTitle").children("input").val("");
        submitButton.siblings("input.add-title").val("");

    }

    function addItemAmericain(submitButton) {
        var ancestor = submitButton.parents().siblings().next(".table-of-contents");
        var title = (submitButton.siblings("input.add-title").val() == undefined ? "" : submitButton.siblings("input.add-title").val());
        var newTitle = '<li class="table-of-contents--item">'
            + '<div>'
            + '<span role="button" tabindex="0" class="mjs-nestedSortable-handle" aria-pressed="false">'
            + '<span class="icon-deplacer"></span>'
            + '<span class="access-hidden">Déplacer ' + title + '</span>'
            + '</span>'
            + '<span class="item-title" role="button" tabindex="0">' + title + '</span>'
            + '<button id="update-content-trigger" type="button" class="update-title-trigger">'
            + '<span class="access-hidden">Modifier ' + title + '</span>'
            + '<span class="icon-modifier"></span>'
            + '</button>'
            + '<button type="button" class="btn btn-outline-primary delete-item" data-delete-item="0">'
            + '<span class="icon-croix2"></span>'
            + '<span class="access-hidden">Supprimer ' + title + '</span>'
            + '</button>'
            + '</div>'
            + '</li>'

        ancestor.append(newTitle);
        submitButton.siblings("input.add-title").val('');
        ancestor.find('li.table-of-contents--item .item-title:last').focus();
        event.preventDefault();
    }

    //add item plan americain
    $(document).on("click", "button.add-item--americain", function () {
        addItemAmericain($(this));
    });

    // add item plan article avec titre
    $(document).on("click", "button.add-item--article-avec-titre", function () {
        addItemArticleAvecTitre($(this));
    });

    // add table of contents item with 'enter' key
    $('.dragndrop-menu > .form-group .add-title').on('keydown', function (eventkey) {
        var planType = $(this).closest('.form-group').siblings('.table-of-contents').attr('data-tableofcontent-type');
        if (eventkey.which === 13) {
            if (planType === 'article-avec-titre') {
                let submitButton = $(this).siblings('.add-item--article-avec-titre');
                addItemArticleAvecTitre(submitButton);
            } else if (planType === 'americain') {
                let submitButton = $(this).siblings('.add-item--americain');
                addItemAmericain(submitButton);
            }
        }
    });

    //delete item
    $(".table-of-contents").on("click", "button.delete-item", function () {
        if ($(this).attr("data-delete-item") == 1) {
            $(this).closest("li").remove();
        } else {
            var tooltip = '<div class="delete-item-warning">'
                + '<p> Attention, cet élément sera supprimé ainsi que tous les éléments qu il contient</p>'
                + '<button type="button" class="icon-croix2 close-tooltip" title="fermer le message">'
                + '</button>'
                + '</div>'
            $(this).after(tooltip);
            $(this).attr("data-delete-item", 1);
            $(this).next().children(".close-tooltip").focus();
        }
    });

    $(".table-of-contents").on("click", "button.close-tooltip", function () {
        $(this).parent().siblings("button.delete-item").focus();
        $(this).closest(".delete-item-warning").remove();
    });
};
