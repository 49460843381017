"use strict";

var updatePageName = function () {
    if ($("ol").hasClass("steps")) {
        if (!$("ol.steps").closest("section").hasClass("boc")) {
            var index = parseInt($(".steps .in-progress").index()) + 1;
            var currentStep = $(".steps .in-progress a").text();
            var pageName = $("title").text();
            var newPageName = "étape " + String(index) + " " + currentStep + " création de texte " + pageName;
            $("title").text(newPageName);
        }
    }
}

var helpManager = function () {

    var init = function () {
        // thematique filter
        var formFilter = $('form[name=thematique_filter]');
        formFilter.find('select[name="thematique_filter[thematique]"]').off('change').on('change', function (e) {
            formFilter.submit();
        });

        // video player for help section
        $('.section-help .px-video-container').each(function (i, el) {
            var video = $(el);

            new InitPxVideo({
                "videoId": video.attr('id'),
                "captionsOnDefault": false,
                "seekInterval": 20,
                "debug": true
            });
        });
    };

    return init();
};
