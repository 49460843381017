"use strict";

var searchManager = function () {
    var gotoPage = function (e) {
        var button = $(e.currentTarget),
            form_name = button.parents('.pagination').data('target'),
            form = $('section.search-result').find('form[name=' + form_name + ']');

        form.find('input[name="' + form_name + '[offset]"]').val(button.data('goto'));
        form.submit();

        return false;
    };

    var gotoYear = function (e) {
        var button = $(e.currentTarget),
            form_name = button.data('target'),
            form = $('section.search-result-boc').find('form[name=' + form_name + ']');

        form.find('input[name="' + form_name + '[year]"]').val(button.data('goto'));
        form.submit();

        return false;
    };

    var loadNode = function (button, async, callback) {
        $.ajax({
            url: button.data('tree'),
            method: 'POST',
            async: async,
            success: function (data) {
                button.data('loaded', true);
                button.parent('div').parent('.table-of-contents--item').children('ol').append($(data));
                toggleContent(button);
                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    var toggleContent = function (button) {
        var ol = button.parent('div').parent('.table-of-contents--item').children('ol');

        if (ol.hasClass("hide")) {
            ol.removeClass('hide');
        } else {
            ol.addClass('hide');
        }

        button.parent('div').parent('.table-of-contents--item').addClass('dots-on');
    };

    var init = function () {
        // search text
        var searchTextForm = $('section.search form[name=search_text]');
        searchTextForm.find('.form-group.references').references();

        // search boc
        $('section.search-result .page-result').off('click').on('click', gotoPage);
        $('section.search-result-boc .year-result').off('click').on('click', gotoYear);

        // search boem
        var boemTree = $('.gestion-boem.search');
        boemTree.on("click", '.toggle-content-trigger', function () {
            var self = $(this);

            if (false !== self.data('loaded')) {
                toggleContent(self);
                return false;
            }

            loadNode(self, true);
        });

        // table analytique
        $('section.search-result-boem').off('click').on('click', 'button.table-analytique', function () {
            var self = $(this);
            window.open(self.data('link'));
        });

        // when result is found...
        var path = boemTree.data('path');
        // if (('undefined' !== typeof path) && (0 !== path.length)) {
        //     $.each(path, function (i) {
        //         var button = $('#a11y-toggle-trigger-' + path[i]);
        //         loadNode(button, false);
        //         button.next().addClass('selected');
        //     });
        // };
    };

    return init();
};