"use strict";

var administrationUtilisateurs = function () {
    /**
     * Init manage binding and initialization
     */
    var resetForm = function (form) {
        form.find('input:text, input:password, select').val('');
        form.find('input:text, input:password, select').removeClass('is-invalid');
        form.find('span.invalid-feedback').remove();
    };

    /**
     * Autocompletify input fields
     */
    var autocompletify = function (input) {
        var property = input.data('property') || 'id';
        var render = input.data('render') || 'value';
        var separator = input.data('separator') || '|';
        var source = input.data('source') || null;
        var options = {
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: 'value',
            template: {
                type: 'custom',
                method: function (value, item) {
                    return item.value;
                }
            },
            list: {
                match: { enabled: true },
                onChooseEvent: function () {
                    var selectedItemValue = $("#user_annudef_form_utilisateur").getSelectedItemData().id;
                    $("#user_annudef_form_dn").val(selectedItemValue);
                },
                onLoadEvent: function () {
                },
                onSelectItemEvent: function () {
                }
            }
        };
        input.easyAutocomplete(options);
    };

    var init = function () {
        // form user
        var formUser = $('section.administration form[name=user_edit_form]');
        formUser.find('select option[value=""]').attr('disabled', true);

        // open dialog user edit form
        var openDialogUserEditForm = function () {
            var dialogEditUser = showDialog('#modal-dialog-edit-user');

            dialogEditUser.on('hide', function () {
                resetForm(formUser);
            });

            return dialogEditUser;
        };

        // add user
        var editUserBtn = $('.administration--gestion-utilisateurs .edit-user > button');
        editUserBtn.off('click').on('click', function () {
            var elementAddUser = '#modal-dialog-add-user';
            var dialogAddUser = showDialog(elementAddUser);

            $(elementAddUser).find('button.annudef').off('click').on('click', function () {
                dialogAddUser.hide();
                var dialogAnnudef = showDialog('#modal-dialog-annudef');
                dialogAnnudef.on('hide', function () {
                    $("#user_annudef_form_dn").val('');
                    $("#user_annudef_form_utilisateur").val('');
                });
            });

            $(elementAddUser).find('button.add').off('click').on('click', function () {
                dialogAddUser.hide();
                var dialogEditUser = openDialogUserEditForm();
            });

            return false;
        });

        if (true === editUserBtn.data('trigger')) {
            var dialogEditUser = openDialogUserEditForm();
        }

        // form list
        var formList = $('section.administration form[name=list-users]');

        // role selects
        formList.find('select.change-role[data-link]').off('change').on('change', function () {
            var self = $(this);
            resetForm(formUser);

            $.ajax({
                url: self.data('link').replace(/\/0$/i, '/' + self.val()),
                method: 'POST'
            }).done(function () {
                self.blur();
            });
        });

        // direction selects
        formList.find('select.change-direction[data-link]').off('change').on('change', function () {
            $.post({ url: $(this).data('link'), method: 'POST', data: { id: $(this).val() } }).done(function () { });
        });

        // service selects
        formList.find('select.change-service[data-link]').off('change').on('change', function () {
            $.post({ url: $(this).data('link'), method: 'POST', data: { id: $(this).val() } }).done(function () { });
        });

        // user delete
        formList.find('button.delete[data-link]').off('click').on('click', function () {
            var self = $(this);
            var element = '#modal-dialog-confirm-delete-user';
            var dialog = showDialog(element);
            var modal = $(element);

            // bind confirm button click
            modal.off('click', 'button.yes').on('click', 'button.yes', function () {
                resetForm(formUser);

                $.ajax({
                    url: self.data('link'),
                    method: 'POST'
                }).done(function () {
                    dialog.hide();
                    document.location = self.data('redirect');
                });
                return false;
            });
            modal.off('click', 'button.no').on('click', 'button.no', function () {
                dialog.hide();
                return false;
            });
        });

        // form annudef
        var formAnnudef = $('form[name=user_annudef_form]');
        autocompletify(formAnnudef.find('.easyautocomplete'));
    };
    return init();
};
