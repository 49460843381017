var nestableTree = function () {
    $('#nestable-tree').nestable();
};

var createMenuItem = function (id, title) {
    var menuItem = '<li class="dd-item dd3-item" data-id="' + String(id) + '">'
        + '<div class="dd-handle dd3-handle">'
        + '<button type="button" class="btn btn-link dd-handle dd3-handle">'
        + '<span class="icon-deplacer"></span>'
        + '</button>'
        + '<span class="access-hidden">Drag</span>'
        + '</div>'
        + '<div class="dd3-content" tabindex="1">'
        + '<span class="node-title">' + String(title) + '</span>'
        + '<button type="button" class="btn btn-link btn-delete-item" data-a11y-dialog-show="modale-attention-delete">'
        + '<span class="icon-croix2">'
        + '</button>'
        + '</div>'
        + '</li>';
    return menuItem;
}
var addMenuItem = function () {
    $(document).on("click", "#add-title-button", function () {
        var list = $(this).closest('.dragndrop-menu').find('ol.dd-list#dd-list-main');
        var count = list.find('li.dd-item[data-id]').length;
        var title = $(this).closest('.form-group').find('input.title').val();
        $(this).closest('.form-group').find('input.title').val('');
        item = createMenuItem(count, title);
        list.append(item);
    })
}
