"use strict";

var step4Manager = function () {
    /**
     * Autocompletify input fields
     */
    var autocompletify = function (inpût) {
        var property = inpût.data('property') || 'id';
        var render = inpût.data('render') || 'value';
        var separator = inpût.data('separator') || '|';
        var source = inpût.data('source') || null;
        var options = $.extend({
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                return element[property] + separator + element[render];
            },
            list: { match: { enabled: true } }
        }, easyautocompleteDefaultOptions);
        inpût.easyAutocomplete(options);
    };
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        var form = $('section.etape-4 form[name=step4_form]');
        var editor = form.find('.text-editor').textEditor(/*{ registry: tableOfContentList }*/);
        // handle visa buttons click
        $('section.etape-4').off('click', 'button[data-visa], button[data-delegation]').on('click', 'button[data-visa]', function (e) {
            e.preventDefault();
            $(this).closest('.btn-group').find('button').removeClass('btn-primary').addClass('btn-outline-primary');
            $('[data-nodelegation][data-novisa]').removeClass('hide');
            var mode = $('form[name=step4_form]').find('input[name*="[mode]"]');
            mode.val(mode.data('delegation'));
            if (false === $(this).data('delegation')) {
                $('[data-nodelegation="false"]').addClass('hide');
                mode.val(mode.data('nodelegation'));
            }
            if (false === $(this).data('visa')) {
                $('[data-novisa="false"]').addClass('hide');
                mode.val(mode.data('novisa'));
            }
            $(this).removeClass('btn-outline-primary').addClass('btn-primary');
            form.removeClass('hide');
            return false;
        });

        // visa trigger click
        $('section.etape-4').find('button[data-visamode="' + $('section.etape-4').find('div[data-visamode]').data('visamode') + '"]').trigger('click');

        // adding a texte applicable
        form.off('click', 'button.add-input').on('click', 'button.add-input', function (e) {
            e.preventDefault();
            var collection = $(this).siblings('.subforms');
            var idx = collection.find('.subform').length;
            var prototype = collection.data('prototype');
            var subform = prototype.replace(/__name__/g, idx);
            collection.append(subform);
            autocompletify(collection.find('.easyautocomplete'));
            return false;
        })
        // removing a texte applicable
        form.off('click', 'button.remove-subform').on('click', 'button.remove-subform', function (e) {
            e.preventDefault();
            var collection = $(this).closest('.subforms');
            $(this).closest('.subform').remove();
            reorderSubforms(collection);
            return false;
        });
        // handle form submission
        // => serialize tree items in hidden data input field
        form.off('submit').on('submit', function () {
            return editor.textEditor('serialize');
            // return false;
        });

        autocompletify(form.find('.easyautocomplete'));
    };
    return init();
};
