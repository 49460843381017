"use strict";

var administrationTextes = function () {
    /**
     * Autocompletify input fields
     */
    var autocompletify = function (input, id) {
        var form = input.parents('form');

        var property = input.data('property') || 'id' || false;
        var render = input.data('render') || 'value';
        var separator = input.data('separator') || '|';
        var source = input.data('source') || null;
        var options = $.extend({
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                if ('undefined' === typeof id) {
                    return element[render];
                }

                return element[property] + separator + element[render];
            },
            list: {
                match: { enabled: true },
                onChooseEvent: function () {
                },
                onLoadEvent: function () {
                },
                onSelectItemEvent: function () {
                }
            }
        }, easyautocompleteDefaultOptions);
        input.easyAutocomplete(options);
    };

    var gotoPage = function (e) {
        var button = $(e.currentTarget),
            form_name = button.parents('.pagination').data('target'),
            form = $('section.administration').find('form[name=' + form_name + ']');

        form.attr('action', $('.administration--gestion-textes').data('action'));
        form.find('input[name="' + form_name + '[offset]"]').val(button.data('goto'));
        form.submit();

        return false;
    };

    var getDate = function (date) {
        var regexp = /^\d{2}([/])\d{2}\1\d{4}$/;
        if (regexp.test(date)) {
            return date.split('/');
        }

        return null;
    };

    var init = function () {
        // form filter textes
        var formFilterTextes = $('form[name=texte_filter_form]');
        var inputNumeroTimbre = formFilterTextes.find('input[name="texte_filter_form[numeroTimbre]"]');
        var inputBoc = formFilterTextes.find('input[name="texte_filter_form[boc]"]');
        var buttonApplyFilter = formFilterTextes.find('button[name="texte_filter_form[apply]"]');
        var buttonExport = $('button.download');

        autocompletify(inputNumeroTimbre);
        autocompletify(inputBoc, true);

        formFilterTextes.off('submit').on('submit', function () {
        });

        buttonApplyFilter.off('click').on('click', function () {
            formFilterTextes.attr('action', $('.administration--gestion-textes').data('action'));
            formFilterTextes.find('input[name="texte_filter_form[offset]"]').val(0);
        });

        buttonExport.off('click').on('click', function () {
            var self = $(this);

            formFilterTextes.attr('action', self.data('action'))
            formFilterTextes.find('input[name="texte_filter_form[offset]"]').val(0);
            formFilterTextes.submit();
        });

        // multipage
        $('section.administration .page-result').off('click').on('click', gotoPage);

        // form list
        var formList = $('section.administration .text-handling-form');

        // change date debut
        formList.find('input.change-date').off('change').on('change', function (e) {
            var self = $(this),
                date;

            date = self.val();
            date = getDate(date);

            // if (null !== date) {
            $.ajax({
                url: self.data('link') + ((null !== date) ? '/' + (date[2] + '/' + date[1] + '/' + date[0]) : ''),
                method: 'POST'
            }).done(function () {
                buttonApplyFilter.trigger('click');
            });
            // }
        });
    };
    return init();
};
