"use strict";

$.widget("custom.references", {
    /**
     * Constructor
     */
    _create: function () {
        this.btn_add = this.element.find('button.add-input');
        this.btn_remove = this.element.find('button.remove-subform');
        this.references = this.element.find('.references');
        this._on(this.btn_add, { click: '_addItemEvent' });
        this._on(this.btn_remove, { click: '_removeItemEvent' });
        this._autocompletify(this.references.find('input.easyautocomplete'))
    },
    /**
     * [EVENT] Add item
     */
    _addItemEvent: function (e) {
        e.preventDefault();
        var idx = this.references.data('idx');
        var prototype = this.references.data('prototype').replace(/__name__/g, idx).replace(/__idx__/g, idx + 1);
        this.references.append(prototype);
        var item = this.references.find('.subform').last();
        this._on(item.find('button.remove-subform'), { click: '_removeItemEvent' });
        this._autocompletify(item.find('input.easyautocomplete'));
        this.references.data('idx', this.references.data('idx') + 1);
        return false;
    },
    /**
     * [EVENT] Remove item
     */
    _removeItemEvent: function (e) {
        e.preventDefault();
        $(e.target).closest('.subform').remove();
        this._reorder();
        return false;
    },
    /**
     * Reorder items
     */
    _reorder: function () {
        var list = this.references.find('.subform');
        list.each(function (idx) {
            // update label if any
            var label = $(this).closest('.subform').data(0 >= idx ? 'label-first' : 'label-next');
            $(this).find('label.form-label').html(label.replace('%idx%', idx + 1));
        });
    },
    /**
     * Autocompletify provided DOM elements
     */
    _autocompletify: function (elements) {
        elements.each(function (idx, element) {
            var property = $(element).data('property') || 'id';
            var render = $(element).data('render') || 'value';
            var separator = $(element).data('separator') || '|';
            var source = $(element).data('source') || null;
            var options = $.extend({
                url: function (query) { return source + '?query=' + query; },
                getValue: function (element) { return element[property] + separator + element[render]; },
                dataType: 'json',
                list: { match: { enabled: true } }
            }, easyautocompleteDefaultOptions);
            $(element).easyAutocomplete(options);
        });
    }
});
