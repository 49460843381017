"use strict";

$.widget("custom.boemMultiSelector", {
    _create: function () {
        this.element.addClass('selector-boems');
        this.prototype = this.element.data('prototype');
        this.selectors = this.element.find('.boems');
        this._on(this.element.find('button.add-input'), { click: '_addBoemSelector' });
        this._makeBoemSelectors();
        return this;
    },
    _makeBoemSelectors: function () {
        this.element.find('.boem-selector').boemSelector({ multi: this });
    },
    _addBoemSelector: function (e) {
        e.preventDefault();
        var idx = this.element.data('idx');
        var count = this.selectors.find('.boem-selector').length;
        this.selectors.append(this.prototype.replace(/__name__/g, idx).replace(/__idx__/g, count + 1));
        this.selectors.find('.boem-selector').last().boemSelector({ multi: this });
        this.element.data('idx', idx + 1);
        return false;
    },
    __removedBoemSelector: function () {
        this.selectors.find('.boem-selector label').each(function (idx) {
            if ($(this).data('label')) {
                $(this).html($(this).data('label').replace(/%idx%/g, idx + 1))
            }
        })
    }
});
