//
// STICKY HEADER
//
var fn_sticky_header = function () {

    var body_wrapper = $('.body-wrapper');
    var region_top_wrapper = $('.region-top-wrapper');
    var header_nav_nav = $('.header-nav-nav');
    
    // + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
    //
    // Au chargement de la page, on ajoute la hauteur de region_top_wrapper à body_wrapper
    // en border-top car region_top_wrapper est fixe.
    //  
    // + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
    var fn_position_top = function () {

        var height_header = $('.header').innerHeight();
        body_wrapper.css({
            'border-top-width' : height_header,
        });

        header_nav_nav.css({
            'height' : height_header,
        });
    }

    fn_position_top();
    

    // + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
    //
    // Au resize de la page, on rejout le calcul des éléments
    //
    // + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
    $(window).on('load, resize', function () { 
        fn_position_top();
    });

    // + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
    //
    // Au scroll, on fait des choses :)
    //
    // + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
    // $(window).scroll(function () {
    //     scrollPos = $(window).scrollTop();
        
    //     if( scrollPos > 250)
    //     {
    //         body.addClass('header-is-sticky');
    //     } 
    //     else 
    //     {
    //         body.removeClass('header-is-sticky');
    //     }
    // });

};