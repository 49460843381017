"use strict";

var gestionBoem = function () {
    // boem tree
    var boemTree = $('.gestion-boem.administration');

    var nsGestionBOEM = boemTree.nestedSortable({
        forcePlaceholderSize: true,
        handle: '.mjs-nestedSortable-handle',
        helper: 'clone',
        items: 'li',
        opacity: .6,
        placeholder: 'placeholder',
        tabSize: 10,
        tolerance: 'pointer',
        toleranceElement: '> div',
        maxLevels: 7,
        isTree: true
    });

    var resetAddBoemElementForm = function (form) {
        form.find('input:text, input:password, select').val('');
        form.find('input:radio, input:checkbox').prop('checked', false);
    };

    var loadNode = function (button, async, callback) {
        $.ajax({
            url: button.data('tree'),
            method: 'POST',
            async: async,
            success: function (data) {
                button.data('loaded', true);
                button.parent('div').parent('.table-of-contents--item').children('ol').append($(data));
                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    var renameNode = function (button, title, async, callback) {
        $.ajax({
            url: button.data('rename') + '?title=' + title,
            method: 'POST',
            async: async,
            success: function (data) {
                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    var addNode = function (button, title, async, callback) {
        $.ajax({
            url: button.data('add') + '?title=' + title,
            method: 'POST',
            async: async,
            success: function (data) {
                button.data('loaded', true);
                button.parent('div').parent('.table-of-contents--item').children('ol').append($(data));

                //put focus on newy created element
                button.parent('div').parent('.table-of-contents--item').last().find(".item-title").focus();

                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    var loadAddNode = function (button, title, async, callback) {
        $.ajax({
            url: button.data('load-add') + '?title=' + title,
            method: 'POST',
            async: async,
            success: function (data) {
                button.data('loaded', true);
                button.parent('div').parent('.table-of-contents--item').children('ol').append($(data));
                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    var addAppendNode = function (button, title, async, callback) {
        $.ajax({
            url: button.data('add-append') + '?title=' + title,
            method: 'POST',
            async: async,
            success: function (data) {
                button.data('loaded', true);
                button.parent('div').parent('.table-of-contents--item').after($(data));

                //put focus on newy created element
                button.parent('div').parent('.table-of-contents--item').next().find(".item-title").focus();

                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    var addPrependNode = function (button, title, async, callback) {
        $.ajax({
            url: button.data('add-prepend') + '?title=' + title,
            method: 'POST',
            async: async,
            success: function (data) {
                button.data('loaded', true);
                button.parent('div').parent('.table-of-contents--item').before($(data));

                //put focus on newy created element
                button.parent('div').parent('.table-of-contents--item').prev().find(".item-title").focus();

                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    var removeNode = function (button, async, callback) {
        $.ajax({
            url: button.data('remove'),
            method: 'POST',
            async: async,
            success: function (data) {
                button.closest("li").remove();
                if ('function' === typeof callback) {
                    callback();
                }
            }
        });
    };

    //move la branche avec les fleches du clavier
    function BranchToMove(handle, dom, tableType) {
        this.handle = handle;
        this.dom = dom;
        this.isSelected = false;
        this.isFirstElement = false;
        this.isLastElement = false;
        this.maxNestingLevel = (tableType == "americain" ? 7 : 0);
        this.level = 0;

        this.setFirstElement = function () {
            var nbPreviousElements = this.dom.prevAll().length;
            this.isFirstElement = (nbPreviousElements == 0) ? true : false;
        };
        this.setLastElement = function () {
            var nbNextElements = this.dom.nextAll().length;
            this.isLastElement = (nbNextElements == 0) ? true : false;
        }
        this.findLevel = function () {
            var ancestors = this.dom.parentsUntil(".gestion-boem", "ol");
            this.level = ancestors.length + 1;
        }
        this.getToggleContentId = function () {
            var toggles = this.dom.closest(".gestion-boem").find("ol[id^='toggle-content-target']");
            var toggleIdList = []

            if (toggles.length != 0) {
                toggles.each(function () {
                    toggleIdSplit = $(this).attr("id").split("-");
                    toggleIdSplitNumber = toggleIdSplit[toggleIdSplit.length - 1];
                    toggleIdList.push(parseInt(toggleIdSplitNumber));
                });
                return Math.max.apply(null, toggleIdList) + 1;
                //Math.max(...toggleIdList) + 1;
            }
            else {
                return (1)
            }
        }
        this.moveUp = function () {
            if (this.isSelected && !this.isFirstElement) {
                this.dom.insertBefore(this.dom.prev("li"));
                putFocusOnNewElement(this.dom);
            }
        };
        this.moveDown = function () {
            if (this.isSelected && !this.isLastElement) {
                this.dom.insertAfter(this.dom.next("li"));
                putFocusOnNewElement(this.dom);
            }
        }
        this.moveRight = function () {

            if (this.isSelected && !this.isFirstElement && this.maxNestingLevel != 0 && this.level < this.maxNestingLevel) {
                var previousElement = this.dom.prev();

                if ($("ol", previousElement).length == 0) {
                    previousElement.append('<ol></ol>');
                    previousElement.children("ol").append(this.dom);
                } else {
                    this.dom.insertAfter(previousElement.children("ol").children("li").last());
                }

                this.dom.parent('ol').siblings('div').removeClass('glow-animation');
                this.dom.parent('ol').siblings('div').addClass('glow-animation');
                putFocusOnNewElement(this.dom);
            }
        }
        this.moveLeft = function () {

            if (this.isSelected && this.level > 1 && this.maxNestingLevel != 0) {
                var nbSiblings = this.dom.siblings().length;
                this.dom.insertAfter(this.dom.parent("ol").parent("li").last());

                if (nbSiblings == 0) {
                    // this.dom.prev("li").children("div").children("button[id^='toggle-content-trigger']").remove();
                    this.dom.prev("li").children("ol").remove();
                }

                putFocusOnNewElement(this.dom);
            }

        }
        this.setSelected = function (value) {
            if (typeof value == "boolean") {
                this.isSelected = value;

                if (value) {
                    this.handle.addClass("handle--selected");
                    this.handle.attr("aria-pressed", "true");
                } else {
                    this.handle.removeClass("handle--selected");
                    this.handle.attr("aria-pressed", "false");
                }
            }
        }
    }

    //change using keyboard
    boemTree.on("focus", ".mjs-nestedSortable-handle", function (event) {
        var tableOfContentType = $(this).closest(".gestion-boem").attr("data-tableofcontent-type");
        var currentBranch = new BranchToMove($(this), $(this).closest("li"), tableOfContentType);

        currentBranch.setFirstElement();
        currentBranch.setLastElement();
        currentBranch.findLevel();

        $(this).bind('keydown', function (eventkey) {
            switch (eventkey.which) {
                case 13:
                case 32:
                    eventkey.preventDefault();
                    currentBranch.setSelected(!this.isSelected);
                    break;
                case 37:
                    currentBranch.moveLeft();
                    break;
                case 38:
                    currentBranch.moveUp();
                    break;
                case 39:
                    currentBranch.findLevel();
                    currentBranch.moveRight();
                    break;
                case 40:
                    currentBranch.moveDown();
                    break;
                case 9:
                    currentBranch.setSelected(false);
                    break;
            }
        });

    });

    function putFocusOnNewElement(dom) {
        var el = $($(".mjs-nestedSortable-handle", dom)[0]);
        var e = $.Event("keydown");
        e.which = 13;
        el.focus().trigger(e);
    }


    boemTree.on("blur", ".mjs-nestedSortable-handle", function () {
        $(this).unbind('keydown');
        $(this).removeClass("handle--selected");
    });

    function getToggleContentId(table) {
        var toggles = table.find("ol[id^='a11y-toggle-content-target']");
        var toggleIdList = []
        if (toggles.length != 0) {
            toggles.each(function () {
                toggleIdSplit = $(this).attr("id").split("-");
                toggleIdSplitNumber = toggleIdSplit[toggleIdSplit.length - 1];
                toggleIdList.push(parseInt(toggleIdSplitNumber));
            });
            return Math.max.apply(null, toggleIdList) + 1;
            // return Math.max(...toggleIdList) + 1;
        }
        else {
            return (1)
        }
    }

    //toggle content
    function toggleContent(trigger) {
        var target = trigger.closest("li").children(".toggle-content-target");
        target.toggleClass("hide");

        if (!target.hasClass("hide")) {
            trigger.children("div").removeClass("arrow-right");
            trigger.children("div").addClass("arrow-down");
            trigger.attr("aria-expanded", "true");
            target.attr("tabindex", "-1");
            target.attr("aria-hidden", "true");
            trigger.closest("li").addClass("dots-on");
        } else {
            trigger.children("div").removeClass("arrow-down");
            trigger.children("div").addClass("arrow-right");
            trigger.attr("aria-expanded", "false");
            target.attr("aria-hidden", "false");
            trigger.closest("li").removeClass("dots-on");
        }
    }

    //click toggle content
    boemTree.on("click", "button.toggle-content-trigger", function () {
        toggleContent($(this))
    });


    //toggle content watch texts
    $(".gestion-boem").on("click", "button.toggle-content-trigger-text", function () {
        var trigger = $(this);
        var target = trigger.nextAll(".toggle-content-target-text:first");
        target.toggleClass("hide");

        if (!target.hasClass("hide")) {
            trigger.children("div").removeClass("arrow-right");
            trigger.children("div").addClass("arrow-down");
            trigger.attr("aria-expanded", "true");
            target.attr("tabindex", "-1");
            target.attr("aria-hidden", "true");
            trigger.closest("li").addClass("dots-on");
        } else {
            trigger.children("div").removeClass("arrow-down");
            trigger.children("div").addClass("arrow-right");
            trigger.attr("aria-expanded", "false");
            target.attr("aria-hidden", "false");
            trigger.closest("li").removeClass("dots-on");
        }
    });


    //update title
    boemTree.on("click", "button.update-title-trigger", function () {
        if (0 !== boemTree.find('#new-title-1').length) {
            return false;
        }

        var title = $(this).siblings(".item-title").first();
        var currrentTitleValue = title.text();
        title.replaceWith('<label for="new-title-1" class="access-hidden">modifier' + currrentTitleValue + '</label>'
            + '<input id="new-title-1" type="text" class="form-input form-control" value="'
            + currrentTitleValue + '">');
        $(this).addClass("update-title-validate").removeClass("update-title-trigger");
        $(this).children("span[class^='icon']").removeClass("icon-modifier").addClass("icon-validation");
        boemTree.find('#new-title-1').val(currrentTitleValue);
    });

    // update title part 2
    boemTree.on("click", "button.update-title-validate", function () {
        var titleInput = $(this).siblings("input");
        const titleValue = titleInput.val();
        titleInput.replaceWith('<span class="item-title" tabindex="0">' + titleValue + '</span>');
        $(this).addClass("update-title-trigger").removeClass("update-title-validate");
        $(this).children("span[class^='icon']").removeClass("icon-validation").addClass("icon-modifier");

        // ajax
        renameNode($(this), titleValue, true);
    });

    // add item gestion du BOEM
    // $(document).on("click", "button.add-item--gestion-boem", function () {

    //ajoutue un attribut sélectionné sur le li parent


    // var currentButton = $(this);
    // var ancestor = currentButton.closest(".gestion-boem");
    // var parent = currentButton.parents().siblings("ol");
    // var title = "Nouvelle sous-section";
    // var toggleId = getToggleContentId(ancestor);
    // var button = currentButton.siblings(':first-child');

    // // var newTitle = '<li class="table-of-contents--item" >'
    // //     + '<div>'
    // //     + '<button type="button" class="toggle-content-trigger text-primary bold"  id="a11y-toggle-trigger-' + toggleId + '" aria-controls="a11-toggle-content-target-' + toggleId + '" aria-expanded="false">'
    // //     + '<span class="access-hidden">déplier ' + title + '</span>'
    // //     + '<div class="arrow-right"></div>'
    // //     + '</button>'
    // //     + '<span class="item-title" tabindex="0">' + title + '</span>'
    // //     + '<button id="update-content-trigger" type="button" class="update-title-trigger">'
    // //     + '<span class="access-hidden">Modifier  ' + title + '</span>'
    // //     + '<span class="icon-modifier"></span>'
    // //     + '</button>'
    // //     + '<button type="button" class="add-item--gestion-boem">'
    // //     + '<span class="access-hidden">Ajouter une sous-section à ' + title + '</span>'
    // //     + '<span class="icon-plus"></span>'
    // //     + '</button>'
    // //     + '<button type="button" class="btn btn-outline-primary delete-item" data-delete-item="0">'
    // //     + '<span class="icon-croix2"></span>'
    // //     + '<span class="access-hidden">Supprimer ' + title + '</span>'
    // //     + '</button>'
    // //     + '<span role="button" tabindex="0" class="mjs-nestedSortable-handle" aria-pressed="false">'
    // //     + '<span class="icon-deplacer"></span>'
    // //     + '<span class="access-hidden">Déplacer' + title + '</span>'
    // //     + '</span>'
    // //     + '</div>'
    // //     + '<ol class="toggle-content-target hide" id="a11y-toggle-content-target-' + toggleId + '" aria-labelledby="a11y-toggle-trigger-' + toggleId + '" tabindex="-1" aria-hidden="true">'
    // //     + '<li>'
    // //     + '<div>'
    // //     + '</div>'
    // //     + '</li>'
    // //     + '</ol>'
    // //     + '</li>';

    // if (false === button.data('loaded')) {
    //     // ajax
    //     loadNode(button, true, function () {
    //         // parent.append(newTitle);

    //         // //shows parent content
    //         var trigger = parent.siblings("div").find('.toggle-content-trigger');
    //         if (parent.hasClass("hide")) {
    //             parent.removeClass("hide");
    //             trigger.children("div").removeClass("arrow-right");
    //             trigger.children("div").addClass("arrow-down");
    //             trigger.attr("aria-expanded", "true");
    //             parent.attr("tabindex", "-1");
    //             parent.attr("aria-hidden", "true");
    //             trigger.closest("li").addClass("dots-on");
    //         }

    //         //put focus on newy created element
    //         // parent.children('.table-of-contents--item').last().find(".item-title").focus();

    //         // ajax
    //         addNode(currentButton, title);
    //     });

    //     return false;
    // }

    // // parent.append(newTitle);

    // //shows parent content
    // var trigger = parent.siblings("div").find('.toggle-content-trigger');
    // if (parent.hasClass("hide")) {
    //     parent.removeClass("hide");
    //     trigger.children("div").removeClass("arrow-right");
    //     trigger.children("div").addClass("arrow-down");
    //     trigger.attr("aria-expanded", "true");
    //     parent.attr("tabindex", "-1");
    //     parent.attr("aria-hidden", "true");
    //     trigger.closest("li").addClass("dots-on");
    // }

    // //put focus on newy created element
    // // parent.children('.table-of-contents--item').last().find(".item-title").focus();

    // // ajax
    // addNode(currentButton, title);
    // });

    //add element to BOEM get selected node
    $(document).on("click", "button.add-item--gestion-boem", function () {
        let selectedNode = $(this).closest('.table-of-contents--item');
        selectedNode.data('addElement', 'true');
        var dialogAddBoemElement = showDialog('#modal-dialog-add-boem-element');
        var currentButton = $(this);
        var parent = currentButton.parents().siblings("ol");
        var button = currentButton.siblings(':first-child');

        // form boem
        var formBoem = $('section.administration form[name=boem_edit_form]');

        // cancel button
        formBoem.find('button.cancel').off('click').on('click', function (e) {
            dialogAddBoemElement.hide();
            return false;
        });

        dialogAddBoemElement.off('hide').on('hide', function () {
            resetAddBoemElementForm(formBoem);
        });

        // submit button
        formBoem.off('submit').on('submit', function (e) {
            var form = formBoem.serializeArray();
            var formData = {};
            $.each(form, function (i, e) {
                formData[e.name] = e.value;
            });

            //hide modal
            dialogAddBoemElement.hide();

            switch (formData['boem_edit_form[place]']) {
                case $(dialogAddBoemElement.node).data('element-prepend'):
                    // ajax
                    addPrependNode(currentButton, formData['boem_edit_form[title]']);
                    break;
                case $(dialogAddBoemElement.node).data('element-append'):
                    // ajax
                    addAppendNode(currentButton, formData['boem_edit_form[title]']);
                    break;
                case $(dialogAddBoemElement.node).data('element-sibling'):
                    if (false === button.data('loaded')) {
                        // ajax
                        loadNode(button, true, function () {
                            //shows parent content
                            if (parent.hasClass("hide")) {
                                parent.removeClass("hide");
                                button.children("div").removeClass("arrow-right");
                                button.children("div").addClass("arrow-down");
                                button.attr("aria-expanded", "true");
                                parent.attr("tabindex", "-1");
                                parent.attr("aria-hidden", "true");
                                button.closest("li").addClass("dots-on");
                            }

                            //put focus on newy created element
                            parent.children('.table-of-contents--item').last().find(".item-title").focus();

                            // ajax
                            addNode(currentButton, formData['boem_edit_form[title]']);
                        });

                        return false;
                    }

                    //shows parent content
                    if (parent.hasClass("hide")) {
                        parent.removeClass("hide");
                        button.children("div").removeClass("arrow-right");
                        button.children("div").addClass("arrow-down");
                        button.attr("aria-expanded", "true");
                        parent.attr("tabindex", "-1");
                        parent.attr("aria-hidden", "true");
                        button.closest("li").addClass("dots-on");
                    }

                    //put focus on newy created element
                    parent.children('.table-of-contents--item').last().find(".item-title").focus();

                    // ajax
                    addNode(currentButton, formData['boem_edit_form[title]']);

                    break;
            }

            return false;
        });
    });
    //delete item
    boemTree.on("click", "button.delete-item", function () {
        var currentButton = $(this);

        var element = '#modal-dialog-remove-boem-element';
        var dialog = showDialog(element);
        var modal = $(element);

        // bind confirm button click
        modal.off('click', 'button.yes').on('click', 'button.yes', function () {

            var elementConfirmation = '#modal-dialog-remove-boem-element-confirmation';
            var dialogConfirmation = showDialog(elementConfirmation);
            var modalConfirmation = $(elementConfirmation);

            modalConfirmation.off('click', 'button.yes').on('click', 'button.yes', function () {
                // ajax
                removeNode(currentButton);
                dialogConfirmation.hide();
                return false;
            });

            modalConfirmation.off('click', 'button.no').on('click', 'button.no', function () {
                dialogConfirmation.hide();
                return false;
            });

            dialog.hide();
            return false;
        });
        modal.off('click', 'button.no').on('click', 'button.no', function () {
            dialog.hide();
            return false;
        });
    });

    boemTree.on("click", "button.close-tooltip", function () {
        $(this).parent().siblings("button.delete-item").focus();
        $(this).closest(".delete-item-warning").remove();
    });

    boemTree.on("click", '.toggle-content-trigger', function () {
        var self = $(this);

        if (false !== self.data('loaded')) {
            return false;
        }

        // ajax
        loadNode(self, true);
    });

}
