"use strict";

var cookieManager = function (container) {
    var displayCookie = function (container) {
        if ((1 != container.length) || ('allow' === $.cookie('cookieconsent_status'))) {
            return false;
        }

        var message = container.data('message');
        var allow = container.data('allow');
        var deny = container.data('deny');
        var policy = container.data('policy');
        var link = container.data('link');
        var href = container.data('href');

        window.cookieconsent.initialise({
            container: container[0],
            palette: {
                popup: {
                    background: "#2ea6dc",
                    text: "#ffffff",
                },
                button: {
                    background: "#207ac3",
                    text: "#ffffff",
                }
            },
            theme: "classic",
            type: "opt-out",
            content: {
                message: message,
                allow: allow,
                deny: deny,
                policy: policy,
                link: link,
                href: href
            },
            law: {
                regionalLaw: false,
            },
            location: true,
            onStatusChange: function (status) {
                if (this.hasConsented()) {
                    var id = '#modal-dialog-confirm-cookie';
                    var dialog = showDialog(id);
                    var modal = $(id);

                    // bind cancel button click
                    modal.off('click', 'button.close').on('click', 'button.close', function () {
                        dialog.hide();
                        return false;
                    });

                    // bind hide event
                    dialog.on('hide', function () {
                        container.empty();
                    });
                }
            },
        });
    };

    var init = function () {
        displayCookie($('#cookie-consent'));
    };
    return init();
};
