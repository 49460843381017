"use strict";

$.widget("custom.textEditor", {
    /*
    options: {
        registry: null
    },
    */
    /**
     * Constructor
     */
    _create: function () {
        this.model = this.element.find('input[name$="[model]"]');
        this.editor = this.element.find('.wysiswyg-container');
        this.wysiwyg = this.element.find('.wysiwyg');
        this.rte = this.wysiwyg.find('.form-group.rte');
        this.ckeditor = CKEDITOR.instances[this.rte.find('textarea.ck-editor').attr('id')];
        this.models = this.element.find('.plans-list');
        this.changer = this.element.find('button.btn.change-plan');
        this.data = this.element.find('input[name="' + this.element.data('holder') + '"]');
        this.accessibility = this.element.find('.accessibility-note');
        this.node = null;
        this.plan = null;
        // handlers
        this._on(this.models.find('button.choose-plan'), { click: '_eventSelectModel' });
        this._on(this.element.find('.table-of-contents'), { focusin: '_updateNodes' });
        this._on(this.element.find('.preambule'), { focusin: '_updateNodes' });
        // default selected if any
        var selected = this.models.find('button.choose-plan[data-selected=1]');
        selected = (0 < selected.length ? selected : this.models.find('button.choose-plan:first'));
        if (0 < selected.length) {
            this.selectModel(selected, this.data.val());
        }
        // item focus
        this.focusPlanElement();
    },
    /**
     * Update current node for saving before serializing
     */
    _updateNodes: function (e) {
        e.preventDefault();
        if (this.node === e.target) return false;
        if ('undefined' !== typeof this.ckeditor) {
            this.storeNodeContent();
            this.ckeditor.setData($(e.target).data('content') || $(e.target.closest('li')).data('content') || '');
        }
        this.node = e.target;
        return false;
    },
    /**
     * 
     */
    storeNodeContent: function () {
        if ($(this.node)) {
            if (0 < $(this.node).closest('li.table-of-contents--item').length) {
                $(this.node).closest('li.table-of-contents--item').data('content', this.ckeditor.getData());
            } else {
                $(this.node).data('content', this.ckeditor.getData());
            }
        }
    },
    /**
     * [event binding] on plan thumbnail click
     */
    _eventSelectModel: function (e) {
        e.preventDefault();
        // if target is current then do nothing
        var target = $(e.target).closest('button.choose-plan');
        if (target.hasClass('selected')) {
            return false;
        }
        // display confirmation dialog
        var dialog = showDialog('#modal-dialog-confirm-plan-selection');
        var cancel = '#modal-dialog-confirm-plan-selection button.cancel';
        var confirm = '#modal-dialog-confirm-plan-selection button.confirm';
        var self = this;
        // bind cancel button click
        $(document).off('click', cancel).on('click', cancel, function () {
            dialog.destroy();
            return false;
        });
        // bind confirm button click
        $(document).off('click', confirm).on('click', confirm, function () {
            dialog.destroy();
            self.selectModel(target);
            return false;
        });
        dialog.show();
        return false;
    },
    /**
     * Default plan element focus
     */
    focusPlanElement: function () {
        let preambule = this.element.find('.plan:visible').find('p.preambule');
        if (0 < preambule.length) {
            preambule.focus();
        } else {
            this.element.find('.plan:visible').find('li.table-of-contents--item .item-title:first:first').focus();
        }
    },
    /**
     * Select/Display the plan matching provided model
     */
    selectModel: function (model, data) {
        if (model.is(":disabled")) {
            return false;
        }
        // hide current plan
        this.wysiwyg.find('.plan').addClass('hide');
        // reset inputs & vars
        this.model.val(model.data('id'));
        this.ckeditor.setData('');
        this.data.val('undefined' !== typeof data ? data : '');
        this.plan = this.wysiwyg.find('.plan.' + model.data('slug'));
        // check correct plan if any
        if (0 < this.plan.length) {
            this.plan.removeClass('hide');
            this.rte.attr('class', this.rte.data('stdsize'));
            this.accessibility.removeClass('hide');
            this.focusPlanElement();
        } else {
            this.rte.attr('class', this.rte.data('fullsize'));
            this.accessibility.addClass('hide');
            var data = (0 < this.data.val().trim().length ? JSON.parse(this.data.val()) : []);
            this.ckeditor.setData(0 < data.length ? data[0].content : '');
        }
        // update visual elements
        this.models.find('button.choose-plan').removeClass('selected');
        model.addClass('selected');
        return false;
    },
    /**
     * Update current node for saving before serializing
     */
    updateCurrent: function (node) {
        this.node = node;
    },
    /**
     * Data serialization before posting
     */
    serialize: function () {
        try {
            this.storeNodeContent();
            this.plan = (this.plan ? this.plan : (this.element.find('.plan:visible') ? this.element.find('.plan:visible') : null));
            if (this.node) $(this.node).data('content', this.ckeditor.getData());
            var items = [];
            if (!this.plan || !this.plan.length) {
                items.push({ style: null, label: null, content: this.ckeditor.getData() });
            } else {
                var list = this.plan.find('ol.table-of-contents');
                var intro = this.plan.find('p.preambule');
                if (intro.length) {
                    items.push({
                        style: 1,
                        label: (intro && 'undefined' !== typeof intro.html() ? intro.html().trim() : null),
                        content: (intro && 'undefined' !== typeof intro.data('content') ? intro.data('content') : null),
                    });
                }
                this.serializeListRecursive(list).forEach(function (item) {
                    items.push(item);
                });
            }
            this.data.val(JSON.stringify(items));
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    /**
     * ol li recursive serialization
     */
    serializeListRecursive: function (list, index) {
        var items = [];
        index = ('undefined' !== typeof index ? index : '');
        var self = this;
        list.find('> .table-of-contents--item').each(function () {
            let number = $(this).parent('ol').find('> li.table-of-contents--item').index($(this)) + 1;
            $(this).parents('li.table-of-contents--item').each(function (idx, li) {
                number = ($(li).parent('ol').find('> li.table-of-contents--item').index($(li)) + 1) + '.' + number;
            });
            var style = $(this).find('> div > .form-group-select-container > select.add-type').val();
            // var index = $(this).find('> div > div.tableContent-numberedTitle input[name=item-idx]').val() || (number + '');
            var index = $(this).find('> div > div.tableContent-numberedTitle input[name=item-idx]').val() || ('');
            var label = $(this).find('> div > span.item-title').html();
            var content = $(this).data('content');
            var item = {
                style: (style || null),
                index: (index ? index.trim() : null),
                label: (label ? label.trim() : null),
                content: (content || null)
            };
            var children = $(this).find('> ol');
            if (0 < children.length) {
                item.children = self.serializeListRecursive(children, item.index);
            }
            items.push(item);
        });
        return items;
    },
});
