"use strict";

var formRequestManager = function () {
    /**
     * Autocompletify input fields
     */
    var autocompletify = function (input) {
        var form = input.parents('form');
        input.data('select', false);
        var property = input.data('property') || 'id';
        var render = input.data('render') || 'value';
        var separator = input.data('separator') || '|';
        var source = input.data('source') || null;
        var options = $.extend({
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                return element[property] + separator + element[render];
            },
            list: {
                match: { enabled: true },
                onChooseEvent: function () {
                    input.data('select', true);
                    form.find('select[name="request_new[service]"]').data('select', false);
                    form.find('select[name="request_new[service]"]').val('');
                },
                onLoadEvent: function () {
                    input.data('select', false);
                },
                onSelectItemEvent: function () {
                    input.data('select', false);
                }
            }
        }, easyautocompleteDefaultOptions);
        input.easyAutocomplete(options);
    };
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        // nouvelle demande
        var formNew = $('form[name=request_new]');
        autocompletify(formNew.find('.easyautocomplete'));

        formNew.find('.validate-user').off('click').on('click', function () {
            var utilisateur = formNew.find('input[name="request_new[utilisateur]"]');

            if (true !== utilisateur.data('select')) {
                return false;
            }

            formNew.find('input[name="request_new[type]"]').val($(this).data('type'));
            formNew.find('.demandes.exergue').hide();
            formNew.find('.demandes-formulaire.exergue.row').show('fade');

            var user = utilisateur.val().substring(utilisateur.val().indexOf(utilisateur.data('separator') || '|', 0) + 1);

            formNew.find('p.user').text(formNew.find('p.user').data('demande-a') + ' ' + user);

            return false;
        });

        formNew.find('.validate-service').off('click').on('click', function () {
            formNew.find('input[name="request_new[type]"]').val($(this).data('type'));
            formNew.find('.demandes.exergue').hide();
            formNew.find('.demandes-formulaire.exergue.row').show('fade');

            var service = $(this).data('label');

            formNew.find('p.user').text(formNew.find('p.user').data('demande-a') + ' ' + service);

            return false;
        });

        formNew.find('.cancel-user').off('click').on('click', function () {
            var utilisateur = formNew.find('input[name="request_new[utilisateur]"]');
            var type = formNew.find('input[name="request_new[type]"]');
            var objet = formNew.find('input[name="request_new[objet]"]');
            var comm = formNew.find('textarea[name="request_new[comm]"]');

            utilisateur.val('');
            utilisateur.data('select', false);

            type.val('');
            objet.val('');
            comm.val('');

            formNew.find('p.user').text('');
            formNew.find('.demandes.exergue').show('fade');
            formNew.find('.demandes-formulaire.exergue.row').hide();

            return false;
        });

        // demandes
        var form = $('form[name=requests]');
        autocompletify(form.find('.easyautocomplete'));

        // affect
        form.find('.affect').off('click').on('click', function () {
            var key = $(this).data('key');
            var dialog = showDialog('#modal-dialog-reaffect-' + key);

            // validate user
            form.find('.validate-user-' + key).off('click').on('click', function () {
                var utilisateur = form.find('input[name="requests[requests][' + key + '][utilisateur]"]');

                if (true !== utilisateur.data('select')) {
                    return false;
                }

                form.find('input[name="requests[requests][' + key + '][type]"]').val($(this).data('type'));

                form.find('input[name="requests[request]"]').val(key);

                form.submit();
                return false;
            });

            // validate service
            form.find('.validate-service-' + key).off('click').on('click', function () {
                form.find('input[name="requests[requests][' + key + '][type]"]').val($(this).data('type'));

                form.find('input[name="requests[request]"]').val(key);

                form.submit();
                return false;
            });

            dialog.off('hide').on('hide', function () {
                var utilisateur = form.find('input[name="requests[requests][' + key + '][utilisateur]"]');

                utilisateur.val('');
            });

            return false;
        });

        // terminate
        form.find('.terminate').off('click').on('click', function () {
            var key = $(this).data('key');
            var type = $(this).data('type');

            form.find('input[name="requests[requests][' + key + '][type]"]').val(type);
            form.find('input[name="requests[request]"]').val(key);

            form.submit();
            return false;
        });

        // relaunch
        form.find('.relaunch').off('click').on('click', function () {
            var id = $(this).data('id');
            var key = $(this).data('key');
            var type = $(this).data('type');

            form.find('input[name="requests[requests][' + key + '][commentaireId]"]').val(id);
            form.find('input[name="requests[requests][' + key + '][type]"]').val(type);
            form.find('input[name="requests[request]"]').val(key);

            form.submit();
            return false;
        });

        // answer 
        form.find('.answer-responsable, .answer-user').off('click').on('click', function () {
            var id = $(this).data('id');
            var key = $(this).data('key');
            var type = $(this).data('type');

            form.find('input[name="requests[requests][' + key + '][commentaireId]"]').val(id);
            form.find('input[name="requests[requests][' + key + '][type]"]').val(type);
            form.find('input[name="requests[request]"]').val(key);

            var answer = form.find('.answer-' + key);
            answer.show('fade');

            // cancel
            answer.find('.cancel').off('click').on('click', function () {
                answer.hide();
                form.find('textarea[name="requests[requests][' + key + '][comm]"]').val('');

                return false;
            });

            // validate
            answer.find('.validate').off('click').on('click', function () {
                form.submit();

                return false;
            });

            return false;
        });
    };
    return init();
};
