"use strict";

var administrationRespoBoem = function () {
    $('.administration--gestion-respo-boem').on('click', '.respo-boem--add-item', function (e) {

        e.preventDefault();

        let list = $(this).parent().siblings('ul');
        let fullTitle = $(this).siblings('.easy-autocomplete').children('input').val();
        let itemNumber = fullTitle.substr(0, fullTitle.indexOf(' '));
        let itemTitle = fullTitle.substr(fullTitle.indexOf(' ') + 1);

        let newElement = '<li>'
            + '<span><span class="bold text-secondary">' + itemNumber + '</span> ' + itemTitle + '</span>'
            + '<button class="btn respo-boem--delete-item">'
            + '<span class="access-hidden">Supprimer' + itemTitle + '</span>'
            + '<span class="icon-croix2"></span>'
            + '</button>'
            + '</li>';

        list.append(newElement);

    });

    $('.administration--gestion-respo-boem').on('click', '.respo-boem--delete-item', function (e) {
        e.preventDefault();
        $(this).closest('li').remove();
    });
};
