"use strict";

var step8Manager = function () {
    /**
     * Init binding and manage some initialization
     */
    var init = function () {
        var formProgress = $('form[name=progress_form]');
        var formReview = $('form[name=review_form]');
        var formValidation = $('form[name=validation_form]');
        /**
         * handle text save form submit
         */
        $('button.btn-text-save').off('click').on('click', function (e) {
            var form = $(this).closest('form');
            form.find('input.form-action').val('1');
            form.submit();
            return false;
        });
        /**
         * handle text print form submit
         */
        $('button.btn-text-upload').off('click').on('click', function (e) {
            var form = $(this).closest('form');
            form.find('input.form-action').val('2');
            form.submit();
            return false;
        });
        /**
         * handle review request (validation-progress) form submit
         */
        $('form[name=progress_form] button[name="progress_form[request]"]').off('click').on('click', function (e) {
            try {
                var form = $(this).closest('form');
                var emergency = form.find('textarea[name*=emergency]').val().trim();
                var isEmergency = (1 === parseInt(form.find('input[name*=isEmergency]:checked').val()));
                if (isEmergency && '' === emergency) {
                    var dialog = showDialog('#modal-dialog-emergency-error');
                    var ok = '#modal-dialog-emergency-error button.ok';
                    $(document).off('click', ok).on('click', ok, function () {
                        dialog.destroy();
                        form.find('textarea[name*=emergency]').focus();
                        return false;
                    });
                    dialog.show();
                    return false;
                }
                var dialog = showDialog('#modal-dialog-progress-confirm');
                // bind button cancel click
                var cancel = '#modal-dialog-progress-confirm .cancel';
                var confirm = '#modal-dialog-progress-confirm .confirm';
                $(document).off('click', cancel).on('click', cancel, function () {
                    dialog.destroy();
                    return false;
                });
                // bind button confirm click
                $(document).off('click', confirm).on('click', confirm, function () {
                    dialog.destroy();
                    form.find('input.form-action').val('0');
                    form.submit();
                    return false;
                });
                dialog.show();
            } catch (error) {
                console.log(error)
            }
            return false;
        });
        /**
         * handle validation request (validation-review) form submit
         */
        $('form[name=review_form] button[name="review_form[accept]"]').off('click').on('click', function (e) {
            var form = $(this).closest('form');
            var dialog = showDialog('#modal-dialog-review-confirm');
            // bind button cancel click
            var cancel = '#modal-dialog-review-confirm .cancel';
            var confirm = '#modal-dialog-review-confirm .confirm';
            $(document).off('click', cancel).on('click', cancel, function () {
                dialog.destroy();
                return false;
            });
            // bind button confirm click
            $(document).off('click', confirm).on('click', confirm, function () {
                dialog.destroy();
                form.find('input.form-action').val('0');
                form.submit();
                return false;
            });
            dialog.show();
            return false;
        });
        /**
         * handle text handling (validation-pending) form submit
         */
        $('form[name=pending_form] button[name="pending_form[handle]"]').off('click').on('click', function (e) {
            var form = $(this).closest('form');
            var dialog = showDialog('#modal-dialog-pending-confirm');
            // bind button cancel click
            var cancel = '#modal-dialog-pending-confirm .cancel';
            var confirm = '#modal-dialog-pending-confirm .confirm';
            $(form).find('input.form-action').val('0');
            $(document).off('click', cancel).on('click', cancel, function () {
                dialog.destroy();
                return false;
            });
            // bind button confirm click
            $(document).off('click', confirm).on('click', confirm, function () {
                dialog.destroy();
                form.find('input.form-action').val('0');
                form.submit();
                return false;
            });
            dialog.show();
            return false;
        });
        /**
         * Manage emergency level definition
         */
        formProgress.find('input[name*=isEmergency]').off('click').on('click', function (e) {
            var isEmergency = (1 === parseInt(formProgress.find('input[name*=isEmergency]:checked').val()));
            if (isEmergency) {
                formProgress.find('.emergency-explanation').removeClass('hide');
            } else {
                formProgress.find('.emergency-explanation').addClass('hide');
            }
            //return false;
        });
        /**
         * Manage acceptance/refusal of emergency
         */
        $('form[name=review_form], form[name=validation_form]').find('input[name*=confirmEmergency]').off('change').on('change', function (e) {
            var form = $(this).closest('form');
            var accept = (1 === parseInt(form.find('input[name*=confirmEmergency]:checked').val()));
            if (accept) {
                form.find('.emergency .level').html(form.find('.emergency .level').data('urgent'));
                form.find('.emergency .explanation').removeClass('hide');
            } else {
                form.find('.emergency .level').html(form.find('.emergency .level').data('normal'));
                form.find('.emergency .explanation').addClass('hide');
            }
        });
        /**
         * Manage acceptance/refusal of text
         */
        formReview.find('.workflow button[name*=refuse]').off('click').on('click', function (e) {
            e.preventDefault();
            var form = $(this).closest('form');
            var dialog = showDialog('#modal-dialog-review-refuse');
            // bind button ok click
            var cancel = '#modal-dialog-review-refuse .cancel';
            var ok = '#modal-dialog-review-refuse .ok';
            $(document).off('click', cancel).on('click', cancel, function () {
                dialog.destroy();
                return false;
            });
            // bind button confirm click
            $(document).off('click', ok).on('click', ok, function () {
                let refusal = $(this).closest('.dialog').find('textarea[name=refusal]');
                if (0 >= refusal.val().trim().length) {
                    refusal.focus();
                } else {
                    dialog.destroy();
                    form.find('input[type=hidden][name*=refusal]').val(refusal.val());
                    form.find('input.form-action').val('0');
                    form.submit();
                }
                return false;
            });
            dialog.show();
            return false;
        });
        /**
         * handle CPBO refusal of text
         */
        formValidation.find('.workflow button[name*=refuse]').off('click').on('click', function (e) {
            e.preventDefault();
            var form = $(this).closest('form');
            var dialog = showDialog('#modal-dialog-validation-refuse');
            // bind button ok click
            var cancel = '#modal-dialog-validation-refuse .cancel';
            var ok = '#modal-dialog-validation-refuse .ok';
            $(document).off('click', cancel).on('click', cancel, function () {
                dialog.destroy();
                return false;
            });
            // bind button confirm click
            $(document).off('click', ok).on('click', ok, function () {
                let refusal = $(this).closest('.dialog').find('textarea[name=refusal]');
                if (0 >= refusal.val().trim().length) {
                    refusal.focus();
                } else {
                    dialog.destroy();
                    form.find('input[type=hidden][name*=refusal]').val(refusal.val());
                    form.find('input.form-action').val('0');
                    form.submit();
                }
                return false;
            });
            dialog.show();
            return false;
        });
        /**
         * handle CPBO validation of text
         */
        formValidation.find('button[name="validation_form[accept]"]').off('click').on('click', function (e) {
            var form = $(this).closest('form');
            var dialog = showDialog('#modal-dialog-validation-confirm');
            // bind button cancel click
            var review = '#modal-dialog-validation-confirm .review';
            var publish = '#modal-dialog-validation-confirm .publish';
            $(document).off('click', review).on('click', review, function () {
                formValidation.find('input[name*=action]').val($(this).data('action'));
                form.find('input.form-action').val('0');
                form.submit();
                return false;
            });
            // bind button confirm click
            $(document).off('click', publish).on('click', publish, function () {
                formValidation.find('input[name*=action]').val($(this).data('action'));
                form.find('input.form-action').val('0');
                form.submit();
                return false;
            });
            dialog.show();
            return false;
        });
        /**
         *
         */
        $('section.etape-8 form fieldset.series').series();
        $('section.etape-8 form fieldset.organisations').organisations();
        $('section.etape-8 form .form-group.references').references();
        $('section.etape-8 form .form-group.modifies').references();
        $('section.etape-8 form .form-group.abroges').references();
        $('section.etape-8 form .form-group.modificatifs').references();
        $('section.etape-8 form .boems').closest('.input-container').boemMultiSelector()
    };
    return init();
};
