//
// Scroll Top
//
var fn_scroll_top = function () {

    var width_window = $(window).innerWidth();

    if (width_window >= 768) {

        $(window).scroll(function () {

            var scrollPos = $(window).scrollTop();

            if (scrollPos > 300) {
                $('.scroll-to-top').fadeIn();
            }
            else {
                $('.scroll-to-top').fadeOut();
            }
        });
    }

    $('.scroll-to-top').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 250);
        return false;
    });

};
