'use strict';

// open requested modal
var showDialog = function (selector) {
    var dialog = new window.A11yDialog($(selector).get(0), $('main').get(0));
    dialog.on('show', function () {
        $('body').addClass('no-scroll');
    });
    dialog.on('hide', function () {
        $('body').removeClass('no-scroll');
    });
    dialog.show();
    return dialog;
};
// /**
//  * Reindex provided subforms collection
//  * @param {Array} collection
//  */
// var reindexSubforms = function (collection) {
//     collection.find('.subform').each(function (idx, subform) {
//         $(subform).find('label, input, textarea, select, .cke').each(function () {
//             if ($(this).attr('id')) {
//                 $(this).attr('id', $(this).attr('id').replace(/\_\d\_/g, '_' + idx + '_'));
//             }
//             if ($(this).attr('name')) {
//                 $(this).attr('name', $(this).attr('name').replace(/\[\d\]/g, '[' + idx + ']'));
//             }
//             if ($(this).attr('for')) {
//                 $(this).attr('for', $(this).attr('for').replace(/\_\d\_/g, '_' + idx + '_'));
//             }
//         });
//     });
// };

/**
 * Reorder provided subforms collection from provided index
 * @param {Array} collection
 */
var reorderSubforms = function (list, start) {
    start = ('undefined' === typeof start ? 1 : start);
    list.find('.subform').each(function (idx) {
        // update label if any
        //if (!$(this).hasClass('subform')) {
        var label = $(this).closest('.subform').data(0 >= idx ? 'label-first' : 'label-next');
        $(this).find('label.form-label').html(label.replace('%idx%', idx + 1));
        // update order input if any
        $(this).find('input[name$="[order]"]').val(idx + start);
        //}
    });
};

/**
 * Prevent leaving page if we are on a step form
 * @param {Object} e
 */
var preventLeaving = function (e) {
    if (!$('section[class^=etape]').data('modified')) {
        return true;
    }
    e.preventDefault();
    var self = this;
    var dialog = showDialog('#modal-dialog-exit-step');
    // bind click button yes
    $(document).on('click', '#modal-dialog-exit-step #yes', function () {
        dialog.hide();
        document.location = $(self).attr('href');
        return false;
    });
    // bind click button no
    $(document).on('click', '#modal-dialog-exit-step #no', function () {
        dialog.hide();
        return false;
    });
    dialog.show();
    return false;
};

// 
var easyautocompleteDefaultOptions = {
    requestDelay: 2000
};

var externalLinks = 'a:not(.no-links-check a, .scroll-to-top, a[href="#"], a[target="_blank"], .nav-skip a, a.cke_button, a.cke_dialog_ui_button, a.cke_specialchar, a.cke_dialog_tab, a.cke_notification_close, .ui-datepicker a)';

// transverse bound functionnalities
var globalManager = function () {
    var resetForm = function (form) {
        form.find('input:text, input:password, select').val('');
        form.find('input:text, input:password, input:file, select').removeClass('is-invalid');
        form.find('span.invalid-feedback').remove();
        form.find('input[name="search_text_reference[link]"]').val('');
        form.find('input[name="search_text_reference[archive]"]').val('');
    };

    var getDate = function (date) {
        var regexp = /^\d{2}([/])\d{2}\1\d{4}$/;
        if (regexp.test(date)) {
            return date.split('/');
        }

        return null;
    };

    /**
     * Autocompletify input fields
     */
    var autocompletify = function (input) {
        var form = input.parents('form');
        input.data('select', false);
        var property = input.data('property') || 'id';
        var render = input.data('render') || 'value';
        var separator = input.data('separator') || '|';
        var source = input.data('source') || null;
        var options = $.extend({
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                return element[property] + separator + element[render];
            },
            list: {
                match: { enabled: true },
                onChooseEvent: function () {
                    input.data('select', true);
                },
                onLoadEvent: function () {
                    input.data('select', false);
                },
                onSelectItemEvent: function () {
                    input.data('select', false);
                }
            }
        }, easyautocompleteDefaultOptions);
        input.easyAutocomplete(options);
    };

    // /**
    //  * Prevent leaving page if we are on a step form
    //  * @param {Object} e
    //  */
    // var preventLeaving = function (e) {
    //     if (!$('section[class^=etape]').data('modified')) {
    //         return true;
    //     }
    //     e.preventDefault();
    //     var self = this;
    //     var dialog = showDialog('#modal-dialog-exit-step');
    //     // bind click button yes
    //     $(document).on('click', '#modal-dialog-exit-step #yes', function () {
    //         dialog.hide();
    //         document.location = $(self).attr('href');
    //         return false;
    //     });
    //     // bind click button no
    //     $(document).on('click', '#modal-dialog-exit-step #no', function () {
    //         dialog.hide();
    //         return false;
    //     });
    //     dialog.show();
    //     return false;
    // };

    var reaffectText = function (e) {
        e.preventDefault();
        var self = this,
            dialog = showDialog('#modal-dialog-reaffect-text');

        dialog.show();
        return false;
    };

    // new reaffectation
    var formReaffectUser = $('form[name=reaffect_text_form]');
    autocompletify(formReaffectUser.find('.easyautocomplete'));

    // validate user
    formReaffectUser.find('.validate-user').off('click').on('click', function () {
        var utilisateur = formReaffectUser.find('input[name="reaffect_text_form[utilisateur]"]');

        if (true !== utilisateur.data('select')) {
            return false;
        }

        formReaffectUser.submit();
        return false;
    });

    var removeConfirm = function (e) {
        e.preventDefault();
        var self = this,
            dialog = showDialog('#modal-dialog-remove-confirm');

        $(dialog.node).find('button.cancel').off('click').on('click', function () {
            dialog.hide();
            return false;
        });

        $(dialog.node).find('button.remove').off('click').on('click', function () {
            dialog.hide();
            document.location = $(self).data('remove');
            return false;
        });

        dialog.show();
        return false;
    };

    // search form text reference
    var formSearchTextReference = $('form[name=search_text_reference]');
    var modalSearchTextReference = '#modal-dialog-search-text-reference';

    var submitPage = function (e) {
        formSearchTextReference.find('input[name="search_text_reference[offset]"]').val(0);
        formSearchTextReference.submit();
        return false;
    };
    formSearchTextReference.find('button[name="search_text_reference[search]"]').off('click').on('click', submitPage);

    var openDialogSearchTextReferenceForm = function (input, callback) {
        // archive true, false, or both (empty)
        var archive = ('undefined' === typeof input.data('archive')) ? null : input.data('archive');
        if (null !== archive) {
            formSearchTextReference.find('input[name="search_text_reference[archive]"]').val(archive);
        }

        // open modal
        var dialogSearchTextReference = showDialog(modalSearchTextReference);

        // apply link
        formSearchTextReference.off('click', 'a.apply').on('click', 'a.apply', function (e) {
            var link = $(this);
            formSearchTextReference.find('input[name="search_text_reference[link]"]').val(link.attr('href'));
            dialogSearchTextReference.hide();
            return false;
        });

        dialogSearchTextReference.on('hide', function () {
            var link = formSearchTextReference.find('input[name="search_text_reference[link]"]');

            if ('' !== link.val()) {
                input.val(link.val());
                if ('function' === typeof callback) {
                    callback();
                }
            }
            $(document).off('click', externalLinks).on('click', externalLinks, preventLeaving);
            resetForm(formSearchTextReference);
            $(modalSearchTextReference).find('div.results > section').remove();

        });
        return dialogSearchTextReference;
    };

    var gotoPage = function (e) {
        var button = $(e.currentTarget),
            offset = button.data('goto');

        formSearchTextReference.find('input[name="search_text_reference[offset]"]').val(offset);
        formSearchTextReference.submit();
        return false;
    };

    formSearchTextReference.off('submit').on('submit', function () {
        var date = getDate(formSearchTextReference.find('input[name="search_text_reference[date]"]').val());

        $.ajax({
            url: formSearchTextReference.attr('action'),
            method: 'POST',
            async: true,
            data: {
                nature: formSearchTextReference.find('select[name="search_text_reference[nature]"]').val(),
                numero: formSearchTextReference.find('input[name="search_text_reference[numero]"]').val(),
                textesReferences: formSearchTextReference.find('input[name="search_text_reference[textes_references]"]').val(),
                date: (null !== date) ? date[2] + '/' + date[1] + '/' + date[0] : null,
                archive: formSearchTextReference.find('input[name="search_text_reference[archive]"]').val(),
                start: formSearchTextReference.find('input[name="search_text_reference[offset]"]').val(),
                limit: formSearchTextReference.find('input[name="search_text_reference[limit]"]').val(),
            },
            success: function (data) {
                $(modalSearchTextReference).find('div.results').html(data);
                $('section.search-texte-reference-result .page-result').off('click').on('click', gotoPage);
            }
        });

        return false;
    });

    /**
     * Event binding and initialization
     */
    var init = function () {
        // $('.header .btn.login a').off('click').on('click', function () {
        //     showDialog('.dialog.modal-signin');
        // });
        // watch changes on inputs, textarea, select and ckeitor to mark step as modified
        $('section[class^=etape]').off('change', 'input, textarea, select').on('change', 'input, textarea, select', function () {
            $('section[class^=etape]').data('modified', true);
        })
        if ('undefined' !== typeof CKEDITOR && 'undefined' !== typeof CKEDITOR.instances) {
            for (var editor in CKEDITOR.instances) {
                CKEDITOR.instances[editor].on('change', function () {
                    $('section[class^=etape]').data('modified', true);
                });
            }
        }
        // if we're in a step form page, then handle prevent leaving on external click
        if (0 < $('section[class^=etape]').length) {
            // var externalLinks = 'a:not(.no-links-check a, .scroll-to-top, a[href="#"], a[target="_blank"], .nav-skip a, a.cke_button, a.cke_dialog_ui_button, a.cke_specialchar, a.cke_dialog_tab, a.cke_notification_close, .ui-datepicker a)';
            $(document).off('click', externalLinks).on('click', externalLinks, preventLeaving);
        }

        $('.text-actions .text-preview').off('click').on('click', function (e) {
            window.open($(this).data('preview'), 'preview');
        });
        $('.text-actions .text-reassignation').off('click').on('click', reaffectText);
        $('.text-actions .text-remove').off('click').on('click', removeConfirm);

        // Editing all input with class search text reference
        $(document).off('mouseover', 'input.search-text-reference + div.easy-autocomplete-container').on('mouseover', 'input.search-text-reference + div.easy-autocomplete-container', function (e, callback) {
            $(e.currentTarget).css('cursor', 'pointer');
        });

        $(document).off('click', 'input.search-text-reference + div.easy-autocomplete-container').on('click', 'input.search-text-reference + div.easy-autocomplete-container', function (e, callback) {
            var ul = $(e.currentTarget).first().children();
            ul = ul[0];
            if (($(ul).children().length !== 0) && $(ul).css('display') !== 'none') {
                return false;
            }

            $(document).off('click', externalLinks);
            var dialogSearchTextReference = openDialogSearchTextReferenceForm($(e.currentTarget).prev('input.search-text-reference'), callback);
            return false;
        });
    }
    return init();
};
