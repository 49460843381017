var burger_menu = function () {

    var header_nav = $('.header-right-nav');
    var burger = $('#burger-menu');

    // > 1200px
    Harvey.attach('screen and (min-width:75em)', {
        on: function () {

            burger.removeAttr('aria-controls', 'menu-burger').removeAttr('id', 'a11y-menu-burger').removeAttr('name', 'a11y-menu-burger').removeAttr('aria-expanded', 'false');

            header_nav.removeAttr('id', 'menu-burger').removeAttr('aria-labelledby', 'a11y-menu-burger').removeAttr('tabindex', '-1').removeAttr('aria-hidden', 'true');

            burger.off('click');

        }
    });

    // < 1199px
    Harvey.attach('screen and (max-width:74.938em)', {
        on: function () {

            // id="a11y-toggle-0" aria-controls="a11y-toggle-content-0" name="a11y-toggle-0" aria-expanded="false"
            burger.attr('aria-controls', 'menu-burger').attr('id', 'a11y-menu-burger').attr('name', 'a11y-menu-burger').attr('aria-expanded', 'false');

            // id="a11y-toggle-content-0" aria-labelledby="a11y-toggle-0" tabindex="-1" aria-hidden="true"
            header_nav.attr('id', 'menu-burger').attr('aria-labelledby', 'a11y-menu-burger').attr('tabindex', '-1').attr('aria-hidden', 'true');

            burger.on('click', function (e) {
                e.preventDefault();
                if (header_nav.hasClass('open')) {
                    header_nav.removeClass('open');
                    $(this).attr('title', 'Ouvrir menu');
                } else {
                    header_nav.addClass('open');
                    $(this).attr('title', 'Fermer menu');
                }
            });

        }
    });


}
