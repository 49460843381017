"use strict";

var dashboardManager = function () {
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        //textes à traiter

        var buttons = $('.dashboard-tat > .dashboard-texteatraiter button.close, .dashboard-texteatraiter button.open');

        // init buttons
        buttons.each(function (index) {
            $(this).find('span.title').text($(this).data('open'));
        });

        buttons.off('click').on('click', function () {
            $(this).toggleClass('close');
            $(this).toggleClass('open');

            if ($(this).hasClass('open')) {
                $(this).text($(this).data('close'));
                $(this).parents('.dashbaord-texteatraiter-main').next().show('fade');
            } else {
                $(this).text($(this).data('open'));
                $(this).parents('.dashbaord-texteatraiter-main').next().hide();
            }

            return false;
        });

        // init filter
        var formFilter = $('form[name=dashboard_filter]');

        // filter btn
        $('.filter').off('click').on('click', function () {
            var dialogFilter = showDialog('#modal-dialog-filter');
            return false;
        });

        return false;
    };

    return init();
};
