    //
    // SWITCH BUTTON
    //
    var switch_button = function () {

        var switchButton = $('.switch-button');

        $('.switch-button').on('click', function (e) {
            e.preventDefault();
            var contentButtons = $(this).closest("table").find("td .switch-button");
            $(this).toggleClass('active');
            if ($(this).parent().is("th")){
                ($(this).hasClass("active")==true) ? contentButtons.addClass("active") : contentButtons.removeClass("active");
            }
        });
    };