"use strict";

var step3Manager = function () {
    var reindex = function (collection) {
        collection.find('.subform').each(function (idx, subform) {
            $(subform).find('label, input, textearea, select').each(function () {
                if ($(this).attr('id')) {
                    $(this).attr('id', $(this).attr('id').replace(/\_\d\_/g, '_' + idx + '_'));
                }
                if ($(this).attr('name')) {
                    $(this).attr('name', $(this).attr('name').replace(/\[\d\]/g, '[' + idx + ']'));
                }
                if ($(this).attr('for')) {
                    $(this).attr('for', $(this).attr('for').replace(/\_\d\_/g, '_' + idx + '_'));
                }
            });
        });
    }
    var autocompletify = function (inpût) {
        var property = inpût.data('property') || 'id';
        var render = inpût.data('render') || 'value';
        var separator = inpût.data('separator') || '|';
        var source = inpût.data('source') || null;
        var options = $.extend({
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                return element[property] + separator + element[render];
            },
            list: { match: { enabled: true } }
        }, easyautocompleteDefaultOptions);
        inpût.easyAutocomplete(options);
    };
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        var form = $('section.etape-3 form[name=step3_form]');
        // add emetteur
        form.off('click', 'button.add-input').on('click', 'button.add-input', function (e) {
            e.preventDefault();
            var collection = $(this).siblings('.subforms');
            var idx = collection.find('.subform').length;
            var prototype = collection.data('prototype');
            var subform = prototype.replace(/__name__/g, idx).replace(/__idx__/g, idx + 1);
            collection.append(subform);
            autocompletify(collection.find('.easyautocomplete'));
            return false;
        })
        // remove emetteur
        form.off('click', 'button.remove-subform').on('click', 'button.remove-subform', function (e) {
            e.preventDefault();
            var collection = $(this).closest('.subforms');
            $(this).closest('.subform').remove();
            reorderSubforms(collection);
            //reindex(collection);
            return false;
        });
        autocompletify(form.find('.easyautocomplete'));

        var inputModificatif = form.find('input[name="step3_form[modificatif]"]');
        autocompletify(inputModificatif);

        $('.boems').closest('.input-container').boemMultiSelector()
    };
    return init();
};
