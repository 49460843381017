"use strict";

var step6Manager = function () {
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        $('section.etape-6 form[name=step6_form]').enclosures();
    };
    return init();
};
