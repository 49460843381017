"use strict";

$.widget("custom.organisations", {
    /**
     * Constructor
     */
    _create: function () {
        this.btn_add = this.element.find('button.add-subform');
        this.btn_remove = this.element.find('button.remove-subform');
        this.organisations = this.element.find('.organisations');
        this._on(this.btn_add, { click: '_addItemEvent' });
        this._on(this.btn_remove, { click: '_removeItemEvent' });
    },
    /**
     * [EVENT] Add item
     */
    _addItemEvent: function (e) {
        e.preventDefault();
        var idx = this.organisations.data('idx');
        var prototype = this.organisations.data('prototype').replace(/__name__/g, idx);
        this.organisations.append(prototype);
        var item = this.organisations.find('.organisation').last();
        this._on(item.find('button.remove-subform'), { click: '_removeItemEvent' });
        this.organisations.data('idx', this.organisations.data('idx') + 1);
        return false;
    },
    /**
     * [EVENT] Remove item
     */
    _removeItemEvent: function (e) {
        e.preventDefault();
        $(e.target).closest('.row.organisation').remove();
        return false;
    }
});
