"use strict";

var administrationBoem = function () {
    /**
     * Autocompletify input fields
     */
    var autocompletify = function (input) {
        var form = input.parents('form');
        input.data('select', false);
        var property = input.data('property') || 'id';
        var render = input.data('render') || 'value';
        var separator = input.data('separator') || '|';
        var source = input.data('source') || null;
        var options = $.extend({
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                return element[property] + separator + element[render];
            },
            list: {
                match: { enabled: true },
                onChooseEvent: function () {
                    input.data('select', true);
                },
                onLoadEvent: function () {
                    input.data('select', false);
                },
                onSelectItemEvent: function () {
                    input.data('select', false);
                }
            }
        }, easyautocompleteDefaultOptions);
        input.easyAutocomplete(options);
    };

    var init = function () {
        // boem tree
        var boemTree = $('.gestion-boem.administration');

        // form boem responsable edit
        var formBoemResponsableEdit = $('form[name=boem_responsable_edit_form]');
        var inputUtilisateur = formBoemResponsableEdit.find('input[name="boem_responsable_edit_form[utilisateur]"]');
        var inputBoem = formBoemResponsableEdit.find('input[name="boem_responsable_edit_form[boem]"]');

        autocompletify(inputUtilisateur);
        autocompletify(inputBoem);

        formBoemResponsableEdit.off('submit').on('submit', function () {
            if ((false === inputUtilisateur.data('select')) || (false === inputBoem.data('select'))) {
                return false;
            }
        });

        // open dialog boem edit form
        var openDialogBoemEditForm = function () {
            var dialogEditBoem = showDialog('#modal-dialog-edit-boem');

            dialogEditBoem.on('hide', function () {
            });

            return dialogEditBoem;
        };

        // add boem
        var editResponsableBtn = $('.administration--gestion-respo-boem .edit-responsable > button');
        editResponsableBtn.off('click').on('click', function () {
            var dialogEditBoem = openDialogBoemEditForm();

            return false;
        });

        // form boem responsables
        var formBoemResponsables = $('form[name=boem_responsables_form]');
        autocompletify(formBoemResponsables.find('.easyautocomplete'));

        // add
        formBoemResponsables.find('.respo-boem--add-item').off('click').on('click', function () {
            var self = $(this);
            var key = self.data('key');
            var boem = formBoemResponsables.find('input[name="boem_responsables_form[responsables][' + key + '][boem]"]');

            if (true !== boem.data('select')) {
                return false;
            }

            $.ajax({
                url: self.data('link') + '?boem=' + boem.val(),
                method: 'POST',
                async: true,
                success: function (data) {
                    var list = self.parent().siblings('ul');
                    list.append($(data));
                }
            });

            boem.data('select', false);
            boem.val('');
            return false;
        });

        // remove
        $(document).on("click", '.respo-boem--delete-item', function () {
            var self = $(this);

            $.ajax({
                url: self.data('link'),
                method: 'POST',
                async: true,
                success: function (data) {
                    self.closest('li').remove();
                }
            });

            return false;
        });

    };
    return init();
};
