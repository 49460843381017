// *
// * A simple plugin to create everything about toggle content
// * Accordeon, modals, burger menu, and any other closable content, you name it
// * We take care of everything, including the accessibility part, so don't worry and keep your DOM clean
// *
//
// * Create a simple toggle effect, a button that open a determined content :
//
// <div data-a11y-toggle-aw>Button</div>
// <div>Content</div>
//
// * Create an object that closes when you click anywhere on the page:
//
// <div data-a11y-toggle-aw data-toggle="click">Button</div>
// <div id>Content</div>
//
// * Create an accordeon, a group of div that closes each other when one is opened:
//
// <div data-toggle="parent">
//   <div data-a11y-toggle-aw>Button</div>
//   <div>Content</div>
// </div>
//
// * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// * /!\ Not use this
// * Create a simple modal (you need to include our CSS file):
//
// <div data-a11y-toggle-aw data-toggle="modal">Button</div>
// <div>Content</div>
//

(function () {
    initToggle();
})();

function initToggle(parent) {
    parent = (typeof parent !== 'undefined') ?  parent : 'body';
    initToggleDOM(parent);
    promptAction(parent);
    closeBtn(parent);
    stopPropagation(parent);
};

/////////////////// CREATE DOM ATTRIBUTES NEEDED
function initToggleDOM(parent) {
    $('[data-a11y-toggle-aw]', parent).each(function (index) {
        var
            btn = $(this),
            btnId = ('a11y-toggle-' + index),
            btnModal = "<button class='modal-close'><span>Fermer</span></button>",
            contentId = ('a11y-toggle-content-' + index);
        content = btn.next();

        // if( btn.is('button') ) {
        //     btn.attr('id', btnId).attr('aria-controls', contentId).attr('name', btnId);
        // } else {
        //     btn.attr('id', btnId).attr('aria-controls', contentId).attr('name', btnId).attr("role","button").attr("tabindex","0");
        // }

        btn.attr('id', btnId).attr('aria-controls', contentId).attr('name', btnId);
        if (!btn.is('button')) {
            btn.attr('role', 'button').attr('tabindex', '0');
        }

        content.attr('id', contentId).attr('aria-labelledby', btnId).attr("tabindex", "-1");

        if (btn.attr("data-toggle") === "modal" && content.closest('.modal-wrapper').length === 0) {

            content.addClass('modal').attr("role", "dialog").prepend(btnModal).append(btnModal).wrap("<div class='modal-wrapper'></div>");

            $(".modal-close", content).attr('aria-controls', contentId).attr("role", "button").attr("tabindex", "0");

            $(".modal-close", content).on("click", { btn: btn, content: content }, function (e) {
                toggleContent(e.data.btn, e.data.content, false);
            });

            $('.modal-close:last-of-type', content).focus(function () {
                setTimeout(function () { $('.modal-close:first-of-type', content).focus(); }, 100);
            });
        }

        toggleAria(btn, content, content.hasClass('open'));
    });
}

/////////////////// TOGGLE ATTRIBUTES ARIA
function toggleAria(btn, content, show) {
    if (show) {
        btn.attr('aria-expanded', 'true');
        //content.attr('aria-hidden', 'false').show();
        content.attr('aria-hidden', 'false');
    } else {
        btn.attr('aria-expanded', 'false');
        //content.attr('aria-hidden', 'true').hide();
        content.attr('aria-hidden', 'true');
    }
}

/////////////////// TOGGLE OPENING CONTENT
function toggleContent(btn, content, show) {
    var
        body = $('body'),
        clickOutside = (btn.attr("data-toggle") === "modal" || btn.attr("data-toggle") === "click"),
        isModal = (btn.attr("data-toggle") === "modal");

    toggleAria(btn, content, show);
    content.toggleClass('open');

    if (isModal) {
        body.toggleClass('modalOpen');
        content.closest('.modal-wrapper').toggleClass('modalOpen');
    }

    if (show) {
        setTimeout(function () {
            content.focus();
        }, 100);

        if (clickOutside) {
            body.bind("click", { btn: btn, content: content }, closeWhenOutsideElement);
        }
        if (isModal) {
            body.bind("keydown", { btn: btn, content: content }, closeWhenEscIsPrompt);
        }
    } else {
        if (clickOutside) {
            body.unbind('click', closeWhenOutsideElement);
        }
        if (isModal) {
            body.unbind("keydown", closeWhenEscIsPrompt);
        }
    }

}

/////////////////// TRIGGER TOGGLE
function promptAction(parent) {
    $('[data-a11y-toggle-aw]', parent).on('click keydown', function (e) {
        if (
            (e.type == "keydown" && (e.keyCode == 13 || e.keyCode == 32)) || (e.type == "click")
        ) {
            var btn = $(this),
                content = btn.next(),
                parent = content.closest('[data-toggle="parent"]'),
                hasParent = parent.length > 0,
                show = !content.hasClass('open');

            if (hasParent && show) {
                $('.open', parent).each(function () {
                    var subContent = $(this),
                        subBtn = $('#' + subContent.attr('aria-labelledby'));

                    toggleContent(subBtn, subContent, false);
                });
            }

            toggleContent(btn, content, show);
        }

    });
}

/////////////////// CLOSE BUTTONS
function closeBtn(parent) {
    $('[data-a11y-toggle-aw-close]', parent).each(function () {
        var btnClose = $(this),
            contentId = $(this).closest('.open').attr('id');

        btnClose.attr('aria-controls', contentId);
    });

    $('[data-a11y-toggle-aw-close]').on('click keydown', function (e) {
        if (
            (e.type == "keydown" && (e.keyCode == 13 || e.keyCode == 32)) || (e.type == "click")
        ) {
            var content = $('#' + $(this).closest('.open').attr('id')),
                btn = $('#' + content.attr("aria-labelledby"));

            toggleContent(btn, content, false);
        }
    });
}

/////////////////// FERMER AVEC UN CLIC EXTERIEUR
var closeWhenOutsideElement = function (e) {
    if (
        !($(e.target) === e.data.btn ||
            $(e.target).closest(e.data.btn).length > 0 ||
            $(e.target) === e.data.content ||
            $(e.target).closest(e.data.content).length > 0)
    ) {
        toggleContent(e.data.btn, e.data.content, false);
    }
}

/////////////////// FERMER LORSQUE LA TOUCHE ESCAPE EST PRESSEE
var closeWhenEscIsPrompt = function (e) {
    if (e.keyCode == 27) {
        toggleContent(e.data.btn, e.data.content, false);
    }
}

/////////////////// CLICK ON TOGGLE ///////////////////
function stopPropagation(parent) {
    $("a, button", "[data-a11y-toggle-aw]", parent).click(function (e) {
        e.stopPropagation();
    });
}
