"use strict";

var administrationPagesHomeImages = function () {
    /**
     * Init manage binding and initialization
     */
    var resetForm = function (form) {
        form.find('input:text, input:password, select').val('');
        form.find('input:text, input:password, input:file, select').removeClass('is-invalid');
        form.find('span.invalid-feedback').remove();
    };

    var init = function () {
        // form page home image
        var formHomeImage = $('section.administration form[name=page_home_image_edit_form]');

        // open dialog home image edit form
        var openDialogHomeImageEditForm = function () {
            var dialogEditHomeImage = showDialog('#modal-dialog-edit-image');

            dialogEditHomeImage.on('hide', function () {
                resetForm(formHomeImage);
            });

            return dialogEditHomeImage;
        };

        // add home image
        var editHomeImageBtn = $('.administration--gestion-autres-publications .edit-image > button');
        editHomeImageBtn.off('click').on('click', function () {
            var dialogEditHomeImage = openDialogHomeImageEditForm();
        });

        if (true === editHomeImageBtn.data('trigger')) {
            var dialogEditHomeImage = openDialogHomeImageEditForm();
        }

        // form list
        var formList = $('section.administration form[name=list-images]');

        // order selects
        formList.find('select.change-ordre[data-link]').off('change').on('change', function () {
            var self = $(this);
            resetForm(formHomeImage);

            $.ajax({
                url: self.data('link').replace(/\/0$/i, '/' + self.val()),
                method: 'POST'
            }).done(function () {
                self.blur();
                document.location = self.data('redirect');
            });
        });

        // home image delete
        formList.find('button.delete[data-link]').off('click').on('click', function () {
            var self = $(this);
            var element = '#modal-dialog-confirm-delete-image';
            var dialog = showDialog(element);
            var modal = $(element);

            // bind confirm button click
            modal.off('click', 'button.yes').on('click', 'button.yes', function () {
                resetForm(formHomeImage);

                $.ajax({
                    url: self.data('link'),
                    method: 'POST'
                }).done(function () {
                    dialog.hide();
                    document.location = self.data('redirect');
                });
                return false;
            });
            modal.off('click', 'button.no').on('click', 'button.no', function () {
                dialog.hide();
                return false;
            });
        });

    };
    return init();
};
