"use strict";

var bocCreation = function () {
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        // form step 1
        var form1 = $('section.boc form[name=boc1_form]');

        form1.find('.switch-button[data-id]').off('click').on('click', function () {
            var checkbox = form1.find('input:checkbox[value="' + $(this).data('id') + '"]');

            $(this).toggleClass('active');
            checkbox.attr('checked', $(this).hasClass('active') ? true : false);

            if (form1.find('input:checkbox:checked').length !== form1.find('input:checkbox').length) {
                form1.find('.switch-all').removeClass('active');
            } else {
                form1.find('.switch-all').addClass('active');
            }

            return false;
        });

        form1.find('.print-boc').off('click').on('click', function () {
            form1.attr('action', $(this).data('link'));
            form1.attr('target', '_blank');
            form1.submit();

            form1.attr('action', '');
            form1.attr('target', '');

            return false;
        });

        form1.find('.switch-all').off('click').on('click', function () {
            $(this).toggleClass('active');

            if (true === $(this).hasClass('active')) {
                form1.find('.switch-button[data-id]').addClass('active');
            } else {
                form1.find('.switch-button[data-id]').removeClass('active');
            }

            form1.find('input:checkbox').attr('checked', $(this).hasClass('active'));

            return false;
        });

        // init buttons with checkboxes
        var switchAll = true;
        form1.find('input:checkbox').each(function () {
            if (true === $(this).prop('checked')) {
                form1.find('.switch-button[data-id="' + $(this).val() + '"]').addClass('active');
            } else {
                switchAll = false;
            }
        });

        if (true === switchAll) {
            form1.find('.switch-all').addClass('active');
        }

        // form step 1
        var form2 = $('section.boc form[name=boc2_form]');

        form2.find('.modify-boc').off('click').on('click', function () {
            document.location = $(this).data('link');

            return false;
        });
    };
    return init();
};
