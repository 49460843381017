"use strict";

var consultation = function () {
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        var formVersions = $('form[name=texte_consultation_versions_form]');
        formVersions.find('select[name=versions]').off('change').on('change', function () {
            var self = $(this);
        });

        var openVersionBtn = formVersions.find('.open-version');
        openVersionBtn.off('click').on('click', function () {
            window.open(formVersions.find('select[name=versions]').val());
            return false;
        });
    };
    return init();
};
