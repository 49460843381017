var autocomplete = function(){
    var options = {
        data: ["lion", "tiger", "leopard", "snow tiger", "snowy owl", "snow leopard", "sheeta", "jaguar"],
        list: {
            match: {
                enabled: true
            }
        }
    };
    
    $("#autocomplete-example-styleguide").easyAutocomplete(options);
}