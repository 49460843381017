"use strict";

$.widget("custom.series", {
    /**
     * Constructor
     */
    _create: function () {
        this.series = this.element.find('input[name*="series"]');
        this.category = this.element.find('select[name*="categorie"]');
        this.serie = this.element.find('select[name*="serie"]');
        this.ready = (0 < this.series.length && 0 < this.category.length && 0 < this.serie.length);
        this._on(this.category, { change: '_selectCategoryEvent' });

    },
    /**
     * [EVENT] Select plan model
     */
    _selectCategoryEvent: function (e) {
        e.preventDefault();
        if (!this.ready) {
            return false;
        }
        var series = JSON.parse(this.series.val());
        this.serie.empty();
        var self = this;
        $.each(series[this.category.val()], function (key, value) {
            self.serie.append($("<option></option>").attr('value', value.id).text(value.label));
        });
        return false;
    }
});
