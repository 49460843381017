$(function () {
    var options = $.extend(
        {},
        $.datepicker.regional["fr"],
        { dateFormat: 'dd/mm/yy' }
    );
    $.datepicker.setDefaults(options);

    $("input.datepicker").datepicker({
        inline: true
    });
});