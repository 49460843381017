var nsAmericain = $('.table-of-contents.americain').nestedSortable({
    forcePlaceholderSize: true,
    handle: '.mjs-nestedSortable-handle',
    helper:	'clone',
    items: 'li',
    opacity: .6,
    placeholder: 'placeholder',
    tabSize: 10,
    tolerance: 'pointer',
    toleranceElement: '> div',
    maxLevels: 7,
    isTree: true
});
var nsArticleSansTitre = $('.table-of-contents.article-sans-titre').nestedSortable({
    forcePlaceholderSize: true,
    handle: '.mjs-nestedSortable-handle',
    helper:	'clone',
    items: 'li',
    opacity: .6,
    revert: 250,
    tabSize: 25,
    tolerance: 'pointer',
    toleranceElement: '> div',
    maxLevels: 1,
    isTree: true
});
var nsArticleAvecTitre = $('.table-of-contents.article-avec-titre').nestedSortable({
    forcePlaceholderSize: true,
    handle: '.mjs-nestedSortable-handle',
    helper:	'clone',
    items: 'li',
    opacity: .6,
    revert: 250,
    tabSize: 25,
    tolerance: 'pointer',
    toleranceElement: '> div',
    maxLevels: 1,
    isTree: true
});