$(document).ready(function () {

    //
    // On donne le focus à la fenetre du site et non au navigateur
    //
    $(window).focus();

    // init mq
    // fn_media_querie();

    // init skip_link
    fn_nav_skip();

    // init burger_menu
    burger_menu();

    //init cover
    fn_cover();

    // init sticky_header
    //fn_sticky_header();

    // // init box reveal
    // fn_menu();

    // init switch button
    switch_button();

    // init scroll_top
    fn_scroll_top();

    // init fn_form_error
    fn_form_error();

    // init modal_dialog
    modal_dialog();

    // init scroll to top
    fn_scroll_top();

    // init simple bar
    // $('.simplebar-wrapper').simplebar();

    // init js specific tpo the project
    // switchSelectInput();
    // addInput();
    // removeInput();

    // subforms manager
    // subformsManager();

    //nestable tree steps 4 & 6
    // nestableTree();
    // addMenuItem();

    //accessible information
    updatePageName();

    // global manager for transverse functionnality and steps
    globalManager();
    cookieManager();
    step1Manager();
    step2Manager();
    step3Manager();
    step4Manager();
    step6Manager();
    step6EnclosureManager();
    step7Manager();
    step8Manager();
    formRequestManager();
    dashboardManager();
    bocCreation();
    administrationNatures();
    administrationUtilisateurs();
    administrationTextes();
    administrationConsolidation();
    administrationBoem();
    administrationPages();
    administrationPagesHomeImages();
    dragon_drop_init();
    gestionBoem();
    searchManager();
    helpManager();
    accountManager();
    consultation();

    //handling on tables of content
    tableOfContentNestedSortable();

    // administrationRespoBoem();

    //test function (just to test some random stuff)
    justToTestStuff();

    //table scroll fixed header - administration gestion des natures de texte
    scrolify($(".administration--gestion-nature-texte table"), 500);
});
