"use strict";

$.widget("custom.enclosures", {
    _create: function () {
        this.delete = this.element.find('button.delete-enclosure');
        this.add = this.element.find('button.add-enclosure');
        this.list = this.element.find('input[name*="[list]"]');
        this._on(this.add, { click: '_addEnclosureEvent' });
        this._on(this.delete, { click: '_deleteEnclosureEvent' });
        this._on(this.element, { submit: '_submitFormEvent' });
    },
    /**
     * Handle enclosure add button click avent
     */
    _addEnclosureEvent: function (e) {
        e.preventDefault();
        var dialog = showDialog('#modal-dialog-add-enclosure');
        // bind click button cancel
        $(document)
            .off('click', '#modal-dialog-add-enclosure .cancel')
            .on('click', '#modal-dialog-add-enclosure .cancel', function () {
                dialog.hide();
                return false;
            });
        dialog.show();
        return false;
    },
    /**
     * Handle enclosure delete button click avent
     */
    _deleteEnclosureEvent: function (e) {
        e.preventDefault();
        // set delete confirmation dialog
        var dialog = showDialog('#modal-dialog-delete-enclosure');
        // bind button cancel click
        var cancel = '#modal-dialog-delete-enclosure .cancel';
        var confirm = '#modal-dialog-delete-enclosure .confirm';
        $(document).off('click', cancel).on('click', cancel, function () {
            dialog.destroy();
            return false;
        });
        // bind button confirm click
        $(document).off('click', confirm).on('click', confirm, function () {
            dialog.destroy();
            $(e.target).closest('div.enclosure.thumbnail').remove()
            return false;
        });
        dialog.show();
        return false;
    },
    /**
     * Handle form submission avent
     */
    _submitFormEvent: function (e) {
        this.list.val(JSON.stringify($('.enclosure.thumbnail').map(function () {
            return $(this).data('id');
        }).get()));
        return true;
    }
});
