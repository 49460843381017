"use strict";

var step2Manager = function () {
    /**
     * reindex after prototype removing
     */
    var reindex = function (collection) {
        collection.find('.subform').each(function (idx, subform) {
            $(subform).find('label, input, textearea, select').each(function () {
                if ($(this).attr('id')) {
                    $(this).attr('id', $(this).attr('id').replace(/\_\d\_/g, '_' + idx + '_'));
                }
                if ($(this).attr('name')) {
                    $(this).attr('name', $(this).attr('name').replace(/\[\d\]/g, '[' + idx + ']'));
                }
                if ($(this).attr('for')) {
                    $(this).attr('for', $(this).attr('for').replace(/\_\d\_/g, '_' + idx + '_'));
                }
            });
        });
    };
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        var form = $('section.etape-2 form[name=step2_form]');
        // adding a emetteur
        form.off('click', 'button.add-subform').on('click', 'button.add-subform', function (e) {
            e.preventDefault();
            var collection = $(this).siblings('.subforms');
            var idx = collection.find('.subform').length;
            var prototype = collection.data('prototype');
            var subform = prototype.replace(/__name__/g, idx);
            collection.append(subform);
            return false;
        })
        // removing a emetteur
        form.off('click', 'button.remove-subform').on('click', 'button.remove-subform', function (e) {
            e.preventDefault();
            var collection = $(this).closest('.subforms');
            $(this).closest('.subform').remove();
            reindex(collection);
            return false;
        });
    }
    return init();
};
