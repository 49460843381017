"use strict";

$.widget("custom.boemSelector", {
    // widget constructor
    _create: function () {
        this.selector = this.element.find('input[name*=fulltitle]');
        this._on(this.element.find('button.remove-subform'), { click: '_removeBoemSelector' });
        if (this.selector.data('source')) {
            this._createSelector();
        }
        return this;
    },
    // create easyAutocomplete selector
    _createSelector: function () {
        var self = this;
        var target = this.selector.data('target');
        var source = this.selector.data('source');
        this.selector.easyAutocomplete({
            url: function (expr) {
                var exclude = [];
                self.element.closest('.boems').find('input[name*=id]:not([name="' + target + '"])').each(function () {
                    if ($(this).val() && $(this).val().trim()) {
                        exclude.push($(this).val());
                    }
                });
                var url = source + '?term=' + expr + (0 < exclude.length ? '&exclude=' + exclude.join(',') : '');
                self.element.find('input[name="' + target + '"]').val(null)
                return url;
            },
            dataType: 'json',
            getValue: 'value',
            list: {
                onChooseEvent: function () {
                    self.element.find('input[name="' + target + '"]').val(self.selector.getSelectedItemData().id);
                }
            }
        });
        return false;
    },
    // remove current selector
    _removeBoemSelector: function (e) {
        e.preventDefault();
        this.element.remove();
        if (this.options.multi && this.options.multi.__removedBoemSelector) {
            this.options.multi.__removedBoemSelector();
        }
        return false;
    }
});
