//
// FORM ERROR
//
var fn_form_error = function () {

    $('.form-error-message a').on('click', function(e) {

        e.preventDefault();
        
        var href = $.attr(this, 'href');
        var header_height_fix = 180;

        // Au clic, on récupère le href des <a>
        // On anime les ancres de .form-error-message vers leurs id
        $('html, body').animate({ scrollTop: $(href).offset().top - header_height_fix }, 500);

        // On mets en  focus le champs relié par son <a> dans le block .form-error-message
        $(href).focus();
    
    });

};

