"use strict";

$.widget("custom.fileselector", {
    /**
     * Constructor
     */
    _create: function () {
        this.file = $('input[type=file]');
        this._on(this.file, { change: '_selectFileEvent' });
    },
    /**
     * Handle file update
     */
    _selectFileEvent: function (e) {
        e.preventDefault();
        var filename = this.file.val().substring(this.file.val().lastIndexOf('\\') + 1);
        this.file.closest('label').find('div.filename').html(filename);
        return false;
    },
    /**
     * Reset file input
     */
    reset: function () {
        this.file.val(null);
        this.file.closest('label').find('div.filename').html(null);
    }
});
