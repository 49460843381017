"use strict";

var step7Manager = function () {
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        var form = $('section.etape-7 form[name=step7_form]');
        /**
         * handle file select
         */
        form.off('change', 'input[name$="[scan]"]').on('change', 'input[name$="[scan]"]', function (e) {
            e.preventDefault();
            var filename = $(this).val().substring($(this).val().lastIndexOf('\\') + 1);
            $(this).closest('label').find('div.filename').html(filename);
            $(this).closest('section[class^=etape]').data('modified', true);
            return false;
        })

        form.find('.scan-download').off('click').on('click', function () {
            document.location = $(this).data('link');
            return false;
        });
    };
    return init();
};
