"use strict";

var step1Manager = function () {
    /**
     * Init manage binding and initialization
     */
    var init = function () {
        var form = $('section.etape-1 form[name=step1_form]');
        var categorie_series = $('section.etape-1 form .text-model.select-type select').data('categorie-series');
        $('section.etape-1 form').submit(function () {
            $('section.etape-1 form .text-model select.form-select').attr('disabled', false);
        });

        $('section.etape-1 button#modele').off('click').on('click', function () {
            showModele(this);
            return false;
        });
        $('section.etape-1 button#existant').off('click').on('click', function () {
            var callback = function () {
                form.submit();
            };

            showExistant(this);
            form.find('input[name="step1_form[reference]"]').next('div.easy-autocomplete-container').trigger('click', [callback]);
            return false;
        });
        $('section.etape-1 form .text-model button[data-enable]').off('click').on('click', function () {
            if ('undefined' === typeof $(this).closest('.text-model').attr('disabled')) {
                enableSelect($(this).data('enable'), false);
            }
            return false;
        });
        $('section.etape-1 form .text-model select.form-select').off('change').on('change', function () {
            // categorie => populate natures
            if ('step1_form_categorie' === $(this).attr('id')) {
                $('section.etape-1 form .text-model.select-serie select').find('option:not(:first)').remove();
                if ('undefined' !== categorie_series[$(this).val()]) {
                    $.each(categorie_series[$(this).val()], function (i, serie) {
                        $('section.etape-1 form .text-model.select-serie select').append($('<option>', {
                            value: serie.id,
                            text: serie.libelle
                        }));
                    });
                }
            }

            if ($(this).val()) {
                $(this).closest('.form-group-select').find('.invalid-feedback').hide();
            }
            enableButton($(this).parent().next('.btn-group-large').children('.step-1-btn-next'), ($(this).val() === ''));
            enableButton($(this).parent().next('.btn-group-large').children('.step-1-btn-previous'), false);
        });
        form.off('submit').on('submit', function (e) {
            if (form.data('confirmed')) {
                return true;
            }
            e.preventDefault();
            var dialog = showDialog('#modal-dialog-validate-typology');
            var modal = $('#modal-dialog-validate-typology');
            // bind confirm button click
            modal.off('click', 'button.confirm').on('click', 'button.confirm', function () {
                form.find('select[name="step1_form[categorie]"]').prop('disabled', false).prop('isDisabled', false)
                    .closest('.select-type').prop('disabled', false).prop('isDisabled', false).removeClass('disabled');
                form.find('select[name="step1_form[nature]"]').prop('disabled', false).prop('isDisabled', false)
                    .closest('.select-nature').prop('disabled', false).prop('isDisabled', false).removeClass('disabled');
                form.find('select[name="step1_form[serie]"]').prop('disabled', false).prop('isDisabled', false)
                    .closest('.select-serie').prop('disabled', false).prop('isDisabled', false).removeClass('disabled');
                form.data('confirmed', true);
                form.submit();
                return false;
            });
            // bind cancel button click
            modal.off('click', 'button.cancel').on('click', 'button.cancel', function () {
                dialog.hide();
                return false;
            });
            dialog.show();
            return false;
        });
        autocompletify(form.find('.easyautocomplete'));
        return false;
    };
    var autocompletify = function (inpût) {
        var property = inpût.data('property') || 'id';
        var render = inpût.data('render') || 'value';
        var separator = inpût.data('separator') || '|';
        var source = inpût.data('source') || null;
        var options = {
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                return element[property] + separator + element[render];
            },
            list: { match: { enabled: true } }
        };
        inpût.easyAutocomplete(options);
    };
    var showExistant = function (el) {
        $('section.etape-1 .etape-1-1').hide();
        $('section.etape-1 .step1-model').hide();
        $('section.etape-1 input[name="step1_form[type]"]').val(0);
        return false;
    };
    var showModele = function (el) {
        // hide subform
        $('section.etape-1 .etape-1-1').hide();
        $('section.etape-1 .step1-model').hide();
        // bind text type selection button click handler
        $('#modal-dialog-type .btn-group button').off('click').on('click', function () {
            $('section.etape-1 input[name="step1_form[type]"]').val($(this).data('type'));
            $('section.etape-1 .step1-model').show();
            updateNatureSelect($(this).data('type'));
            dialogConfirmStep1.destroy();
            return false;
        });
        var dialogConfirmStep1 = showDialog('#modal-dialog-type');
        return false;
    }
    //
    var updateNatureSelect = function (type) {
        var target = $('section.etape-1 form[name=step1_form] select[name*=nature]');
        target.find('option:not(:first)').remove();
        $(target.data('type-natures')[type]).each(function () {
            target.append('<option value="' + this.id + '">' + this.label + '</option>');
        });
    }
    var enableSelect = function (select, disable) {
        // close all
        $('section.etape-1 form .text-model').addClass('disabled');
        $('section.etape-1 form .text-model').attr('disabled', true);
        $('section.etape-1 form .text-model select').attr('disabled', true);
        $('section.etape-1 form .text-model button:not(.btn-tooltip)').attr('disabled', true).addClass('btn-disabled');

        // enable request select
        $('section.etape-1 form .text-model.select-' + select).toggleClass('disabled');
        $('section.etape-1 form .text-model.select-' + select).attr('disabled', disable);
        $('section.etape-1 form .text-model.select-' + select + ' select').attr('disabled', disable);
        $('section.etape-1 form .text-model.select-' + select + ' .step-1-btn-previous').attr('disabled', disable).toggleClass('btn-disabled');
        $('section.etape-1 form .text-model.select-' + select + ' select').trigger('change');
    }
    var enableButton = function (button, disable) {
        $(button).attr('disabled', disable).toggleClass('btn-disabled');
    }
    return init();
};
