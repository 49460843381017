"use strict";

$.widget("custom.natureEditor", {
    options: {
        dialog: null
    },
    /**
     * Constructor
     */
    _create: function () {
        this.dialog = $(this.options.dialog);
        this.natures = this.element.find('tr.nature');
        // handlers
        this._on(this.natures.find('button.edit-nature'), { click: '_eventEditNature' })
        this._on(this.natures.find('button.delete-nature'), { click: '_eventDeleteNature' })
        this._on(this.element.find('button.add-nature'), { click: '_eventAddNature' });
        this._on(this.element.find('input[type=checkbox].nature-model'), { click: '_toggleRelation' });
        // init
        if (this.element.attr('data-error')) {
            this._showEditDialog();
        }
    },
    /**
     * set form inputs values from provided nature
     * @param <Nature> nature default undefined
     */
    _initForm: function (nature, action) {
        if (!this.dialog.length) return false;
        nature = ('undefined' !== typeof nature ? nature : { id: null, libelle: null, code: null, visa: true, modificatif: true });
        action = ('undefined' !== typeof action ? action : 'upsert');
        this.element.find('input[name*="action"]').val(action);
        this.element.find('input[name*="id"]').val(nature.id);
        this.element.find('input[name*="libelle"]').val(nature.libelle);
        this.element.find('input[name*="code"]').val(nature.code);
        this.element.find('input[name*="visa"]').prop('checked', nature.visa);
        this.element.find('input[name*="modificatif"]').prop('checked', nature.modificatif);
        if (nature.id) {
            this.element.find('p.notice').addClass('hide');
        } else {
            this.element.find('p.notice').removeClass('hide');
        }
        this.element.find('.is-invalid').removeClass('is-invalid');
        this.element.find('span.invalid-feedback').remove();
        return false;
    },
    /**
     * [event binding] on edit nature click
     */
    _eventEditNature: function (e) {
        e.preventDefault();
        if (!this.dialog.length) return false;
        var target = $(e.target).closest('tr.nature');
        this._initForm(target.data('nature'));
        this._showEditDialog();
        return false;
    },
    /**
     * [event binding] on delete nature click
     */
    _eventDeleteNature: function (e) {
        e.preventDefault();
        if (!this.dialog.length) return false;
        var dialog = showDialog('#modal-dialog-confirm-delete-nature');
        var self = this;
        $('#modal-dialog-confirm-delete-nature button.cancel').off('click').on('click', function () {
            dialog.destroy();
            return false;
        });
        $('#modal-dialog-confirm-delete-nature button.confirm').off('click').on('click', function () {
            var target = $(e.target).closest('tr.nature');
            self._initForm(target.data('nature'), 'delete');
            self.element.submit();
            return false;
        });
        dialog.show();
        return false;
    },
    /**
     * [event binding] on add nature click
     */
    _eventAddNature: function (e) {
        e.preventDefault();
        if (!this.dialog.length) return false;
        this._initForm();
        this._showEditDialog();
        return false;
    },
    /**
     * Show nature edition popin
     */
    _showEditDialog: function () {
        if (!this.dialog.length) return false;
        var dialog = showDialog(this.dialog);
        var self = this;
        $(this.options.dialog + ' button[name*=validate]').off('click').on('click', function () {
            dialog.destroy();
            self.element.submit();
            return false;
        });
        dialog.show();
        return false;
    },
    /**
     * Togle Nature -> Model relationship
     */
    _toggleRelation: function (e) {
        $.ajax({
            url: $(e.target).data('url'),
            method: 'post'
        }).done(function () {
            $(e.target).prop('checked', !$(e.target).prop('checked'));
        });
        return false;
    }

});
