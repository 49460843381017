"use strict";

var administrationConsolidation = function () {
    /**
     * Autocompletify input fields
     */
    var autocompletify = function (input) {
        var form = input.parents('form');

        var property = input.data('property') || 'id' || false;
        var render = input.data('render') || 'value';
        var separator = input.data('separator') || '|';
        var source = input.data('source') || null;
        var options = $.extend({
            url: function (query) {
                return source + '?query=' + query;
            },
            dataType: 'json',
            getValue: function (element) {
                return element[property] + separator + element[render];
            },
            list: {
                match: { enabled: true },
                onChooseEvent: function () {
                },
                onLoadEvent: function () {
                },
                onSelectItemEvent: function () {
                }
            }
        }, easyautocompleteDefaultOptions);
        input.easyAutocomplete(options);
    };

    var init = function () {
        var section = $('section.administration .administration--consolidation');

        section.find('a.abrogate').off('click').on('click', function () {
            var self = $(this);
            var element = '#modal-dialog-confirm-abrogate';
            var dialogAbrogate = showDialog(element);
            var modal = $(element);

            dialogAbrogate.on('hide', function () {
            });

            // bind confirm button click
            modal.off('click', 'button.yes').on('click', 'button.yes', function () {
                document.location = self.attr('href');
                dialogAbrogate.hide();
                return false;
            });
            modal.off('click', 'button.no').on('click', 'button.no', function () {
                dialogAbrogate.hide();
                return false;
            });

            return false;
        });

        // form search nor
        var formSearchNor = $('form[name=consolidate_search_nor_form]');
        var inputOriginal = formSearchNor.find('input[name="consolidate_search_nor_form[original]"]');
        var inputNor = formSearchNor.find('input[name="consolidate_search_nor_form[nor]"]');

        autocompletify(inputOriginal);
        autocompletify(inputNor);
    };
    return init();
};
